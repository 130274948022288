import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { AppString } from './../../components/Const';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';

class SignOutButton extends Component {

    doSignOut = e => {
    	this.props.firebase.doSignOut();
    	localStorage.removeItem(AppString.ID);
	    localStorage.removeItem(AppString.NICKNAME);
	    localStorage.removeItem(AppString.USER_ROLE);
	    localStorage.removeItem(AppString.PHOTO_URL);
	    localStorage.removeItem(AppString.ABOUT_ME);
	    this.props.history.push(ROUTES.LANDING);
    }

    render () {
        return (
			<button type="button" onClick={this.doSignOut}>
			   Sign Out
			</button>
        )
    }
}
export default compose(withRouter,withFirebase)(SignOutButton)
//export default withFirebase(SignOutButton);