import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import CaseShow from '../Case/CaseShow';
import CaseEdit from '../Case/CaseEdit'
import CaseCreate from '../Case/CaseCreate';
import SelectServices from '../Case/SelectServices';
import CaseType from '../Case/CaseType'
import Footer from '../Navigation/Footer';
import Content from '../Content';
import Availability from '../Account/Availability';
import Services from '../Services';
import * as ROUTES from '../../constants/routes';

//import { withFirebase } from '../Firebase';

import { withAuthentication  } from '../Session';

const App = () => (
      <Router>
        <div>
        <Navigation />
          <hr />
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.ADMIN} component={AdminPage} />
          <Route path={ROUTES.CASE_DISPLAY + ":id"} component={CaseShow} />
          <Route path={ROUTES.CASE_EDIT + ":id"} component={CaseEdit} />
          <Route path={ROUTES.CASE_CREATE} component={CaseCreate} />
          <Route path={ROUTES.AVAILABILITY} component={Availability} />
          <Route path={ROUTES.SERVICES} component={Services} />
          <Route path={ROUTES.JOB_TYPE} component={CaseType} />
          <Route path={ROUTES.SELECT_SERVICES} component={SelectServices} />
          <Route exact path={"/page/:id"} component={Content} />
        <Footer/>
        </div>
      </Router>
    );

export default withAuthentication(App);