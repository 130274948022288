import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { Button, Paper } from '@material-ui/core';
import firebase from 'firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { AppString } from './../../components/Const';

const INITIAL_STATE = {
  error: null,
};

class AccountRequestForm extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      ...INITIAL_STATE,
      userRole: localStorage.getItem('userRole'),
      account_type: [],
      approved_roles: [],
      Date: firebase.firestore.Timestamp.fromMillis(moment().valueOf()),
      userId: localStorage.getItem(AppString.ID),
      uid: '',
      requestId: '',
      is_checked : 0
    }
  }

  componentDidMount() {
    const that = this;
    this.props.firebase.accountRequestDB().where("userId", "==", this.state.userId)
      .get({source:'server'})
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              //console.log(doc.data())
              that.setState({
                account_type: doc.data().account_type,
                Date: doc.data().Date,
                requestId: doc.id,
                approved_roles:doc.data().approved_roles
              })
          })
    })

    this.props.firebase.usersDB().where("userId", "==", this.state.userId)
      .get({source:'server'})
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              that.setState({
                uid: doc.id
              })
          })
      })

  }

  onSubmit = event => {
    const { account_type, userId, Date, uid, approved_roles, userRole, requestId } = this.state;
    event.preventDefault();

    if(requestId){
      const updateRef = this.props.firebase.accountRequestDB().doc(requestId);
      updateRef.set({
          userId,
          Date,
          uid,
          account_type,
          userRole,
          approved_roles
      }).then((docRef) => {
         toast('Successfully Updated', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'success'
         });
      }).catch((error) => {
          toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
              type: 'error',
          });
      });

    }else{
        this.props.firebase.accountRequestDB().add({
          userId,
          Date,
          uid,
          account_type,
          userRole,
          approved_roles
        }).then((docRef) => {
           toast('Request Successfully Placed', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
              type: 'success'
          });
        }).catch((error) => {
            console.error("Error adding document: ", error);
            toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                type: 'success',
            });
        });
    }
  }

  onChange = e => {
    const options = this.state[e.target.name]
    let index
    if (e.target.checked) {
      options.push(e.target.value)
    } else {
      index = options.indexOf(+e.target.value)
      options.splice(index, 1)
    }

    let is_checked = (options.length>0) ? 1 : 0;
    //console.log(options);
    this.setState({ [e.target.name]: options, is_checked : is_checked })
  };

  render() {
    const { account_type, error, userRole, requestId, approved_roles, is_checked } = this.state;
    
    const is_designer = account_type.indexOf('Designer')>=0 ? true : false;
    const is_denturist = account_type.indexOf('Denturist')>=0 ? true : false;
    const is_mill = account_type.indexOf('Mill')>=0 ? true : false;


    const is_designer_approved = approved_roles.indexOf('Designer')>=0 ? true : false;
    const is_denturist_approved = approved_roles.indexOf('Denturist')>=0 ? true : false;
    const is_mill_approved = approved_roles.indexOf('Mill')>=0 ? true : false;

    const isInvalid =
      is_checked===0;

    return (
      <form onSubmit={this.onSubmit}>
      {
        (userRole!=="Denturist") &&
        <Paper className="pt-0">
          <label>
            <input 
              type="checkbox" 
              name="account_type" 
              value="Denturist" 
              onChange={this.onChange} 
              checked={is_denturist}
              disabled={is_denturist_approved}
            />
            <span>Request Denturist Account</span>
          </label>
        </Paper>
      }
        
      {
        (userRole!=="Designer") &&
        <Paper className="pt-0">
          <label>
            <input 
              type="checkbox" 
              name="account_type" 
              value="Designer" 
              onChange={this.onChange}
              checked={is_designer}
              disabled={is_designer_approved}
            />
            <span>Request Designer Account</span>
          </label>
        </Paper>
      }

      {
        (userRole!=="Mill") &&
        <Paper className="pt-0">
          <label>
            <input 
              type="checkbox" 
              name="account_type" 
              value="Mill" 
              onChange={this.onChange} 
              checked={is_mill}
              disabled={is_mill_approved}
            />
            <span>Request Mill Account</span>
          </label>
        </Paper>
      }
       
       {
          (requestId==="" || (requestId!=="" && is_checked===1)) &&
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInvalid}
              style={{ marginTop:'20px' }}
            >
              Send Request
            </Button>
       } 

       {
          (requestId!=="" && approved_roles.length===0) && 
          <p>Your request is pending for approval</p>
       }

       {
          (requestId!=="" && approved_roles.length>0) && 
          <p>Your request is approved for {approved_roles.join(' and ')}</p>
       }
        
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

export default withFirebase(AccountRequestForm);