import React, { Component } from 'react';
import { AppString } from './../../components/Const';
import { withFirebase } from '../Firebase';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

class EditAvailability extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      id: localStorage.getItem(AppString.ID),
      userRole: localStorage.getItem('userRole'),
      show: false,
      startDate: '',
      endDate: '',
      unavailability_reason: '',
      arr:[],
      events: [],
      options: [],
      eventId: '',
      userId: '',
      markBooked: false
    };

  }

  onSubmit = event => {
    event.preventDefault();
    const unavailability_reason = this.state.unavailability_reason;
    const userId = this.state.userId;
    const title = this.state.unavailability_reason;
    const allDay = false;
    const start = this.state.startDate;
    const end = this.state.endDate;
    const timeSlot = this.state.timeSlot;
    const markBooked  = this.state.markBooked;
    const date = moment(this.state.startDate).format('YYYY-MM-DD');

    const updateRef = this.props.firebase.availabilityDB().doc(this.state.eventId);
    updateRef.set({
      allDay,
      userId,
      start,
      end,
      timeSlot,
      unavailability_reason,
      title,
      markBooked,
      date
    }).then((docRef) => {
        toast('Successfully Updated', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'success', onClose: () => { this.handleClose(event);  }
        });
    }).catch((error) => {
        console.error("Error adding document: ", error);
        toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'error',
        });
    });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = (event) => {
      this.setState({ markBooked: event.target.checked })
  }

  handleClose = (event) => {
    this.setState({ show: false });
  }

  deleteEvent = (event) => {
      const id = event.target.id;
      //const that = this;
      this.props.firebase.availabilityDB().doc(id).delete().then(() => {
        this.handleClose(event);
        toast('Successfully deleted', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'success', onClose: () => { window.location.reload() }
        });
      }).catch((error) => {
        console.error("Error deleting record: ", error);
        toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
              type: 'error',
          });
      });
  }

  componentDidMount() {
    //const show = this.props.show;
    const eventId = this.props.eventId;
    if(eventId!==""){
      const ref = this.props.firebase.availabilityDB().doc(eventId);
      ref.get().then((doc) => {
          if (doc.exists) {
              this.setState({
                  startDate: doc.data().start,
                  endDate: doc.data().end,
                  eventId: this.props.eventId,
                  userId: doc.data().userId,
                  timeSlot: doc.data().timeSlot,
                  markBooked: doc.data().markBooked
              });
          }
      })
    }
  }

  componentWillReceiveProps(nextProps){
    //console.log(nextProps)
    const eventId = nextProps.eventId;
    if(eventId!==""){
      const ref = this.props.firebase.availabilityDB().doc(eventId);
      ref.get().then((doc) => {
          if (doc.exists) {
              console.log(doc.data())
              this.setState({
                  show: nextProps.show,
                  startDate: doc.data().start,
                  endDate: doc.data().end,
                  eventId: this.props.eventId,
                  userId: doc.data().userId,
                  timeSlot: doc.data().timeSlot,
                  markBooked: doc.data().markBooked
              });
          }
      })
    }
  }

  render() {
    //const { options } = this.state;
    let startTime = moment(this.state.startDate).format('h:mm a');
    let endTime = moment(this.state.endDate).format('h:mm a');
    let startDate = moment(this.state.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.state.endDate).format('YYYY-MM-DD');
    //console.log(this.state.events)
    //const isInvalid = userName === '' || error!==null;

    return (
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.show==="edit"} onHide={this.handleClose}
                 backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Availability</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-12">
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          disabled
                          name="startDate"
                          label="Start Date"
                          type="text"
                          id="startDate"
                          value={startDate ? startDate : this.props.startDate}
                          onChange={this.onChange}
                        />
                      
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          disabled
                          name="endDate"
                          label="End Date"
                          type="text"
                          id="endDate"
                          value={endDate ? endDate : this.props.endDate}
                          onChange={this.onChange}
                        />

                        <div>
                          <ul>
                            <li>
                                <label>Time Slot</label>
                                <p>{startTime+' - '+endTime}</p>
                            </li>
                            <li>
                              <p style={{ padding:"6px 8px" }}>
                                <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={ this.state.markBooked }
                                        onChange={this.handleChange}
                                        value="1"
                                        color="primary"
                                      />
                                    }
                                    label=" Booked"
                                />
                              </p>  
                            </li>
                          </ul>
                        </div>

                  </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn" onClick={this.handleClose}>
                    Close
                  </button>&nbsp;
                  <button className="btn" onClick={this.onSubmit}>Save Changes</button>&nbsp;
                  <button className="btn" id={this.props.eventId} onClick={this.deleteEvent}>Delete</button>
                </Modal.Footer>
          </Modal>

    );
  }
}

export default withFirebase(EditAvailability);