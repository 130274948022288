import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import ReactLoading from 'react-loading';
import { withStyles } from '@material-ui/core/styles'; 
import {TextField, Paper, Container, Typography, Button, Select, MenuItem,InputLabel,FormControl} from '@material-ui/core';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './signup.css';
import { AppString } from './../../components/Const';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = (theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SignUpPage = () => (
  <Container component="main" maxWidth="xs">
  <div className="sign-box">
      <Paper>
        <Typography component="h1" variant="h5" color="primary">
          Sign Up
        </Typography>
    <SignUpForm />
  </Paper>
  </div>
  </Container>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  role:'',
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, checkedA: false };
  }


  handleChange = event =>{
    console.log(event.target.name)
    this.setState({ ...this.state, checkedA: event.target.checked });
  }
 
  onSubmit = event => {
    this.setState({ isLoading: true })
    const { email, passwordOne } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        this.props.firebase.usersDB().add({
          username:this.state.username,
          email:this.state.email,
          userId:authUser.user.uid,
          userRole:this.state.role,
          status:1
        }).then((docRef) => {
           this.setState({ isLoading: false });
          //this.setState({ ...INITIAL_STATE });
         //this.props.history.push(ROUTES.HOME);
          localStorage.setItem(AppString.ID, authUser.user.uid)
          localStorage.setItem(AppString.NICKNAME, this.state.username)
          localStorage.setItem(AppString.USER_ROLE, this.state.role)
          localStorage.setItem(AppString.PHOTO_URL, '')
          localStorage.setItem(AppString.ABOUT_ME, '')
         toast('Account successfully created', { position: toast.POSITION.TOP_CENTER, autoClose: 2000, 
          type: 'success', onClose: () => {this.props.history.push(ROUTES.HOME); this.setState({ ...INITIAL_STATE })}
         });

      }).catch(error => {
        this.setState({ error: error, isLoading: false });
        toast(error.message, { draggable:false,position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'error',
        });
      })
      })
      .catch(error => {
        this.setState({ error: error, isLoading: false });
        toast(error.message, { draggable:false,position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'error',
        });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      role,
      checkedA
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      role === '' ||
      checkedA === false ||
      username === '';
      const { classes } = this.props;
    return (
      <form noValidate onSubmit={this.onSubmit}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Full Name"
            name="username"
            autoFocus
            value={username}
            onChange={this.onChange}
          />
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={this.onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordOne"
            label="Password"
            type="password"
            id="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
          />
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordTwo"
            label="Confirm Password"
            type="password"
            id="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
          />
          <FormControl className={classes.formControl}>
        <InputLabel htmlFor="role-id" required>Select a Role</InputLabel>
          <Select
          value={this.state.role}
          onChange={this.onChange}
          inputProps={{
            name: 'role',
            id: 'role-id',
          }}
        >
          <MenuItem value={'Denturist'}>Denturist</MenuItem>
          <MenuItem value={'Mill'}>Mill</MenuItem>
          <MenuItem value={'Designer'}>Designer</MenuItem>
        </Select>
        </FormControl>

       
          <label>
            <Checkbox
              checked={this.state.checkedA}
              onChange={this.handleChange}
              value="checkedA"
              color="default"
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />

            
          </label>
          <span className="terms-text">To continue, you must agree to our <a href="/page/terms-and-conditions" target="_blank" rel="noopener noreferrer">terms & conditions</a> and <a href="/page/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span> 
        


        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isInvalid}
          >
          Sign Up
        </Button>

        {/*error && <p>{error.message}</p>*/}
        {this.state.isLoading ? (
          <div className="viewLoading">
            <ReactLoading
              type={'spin'}
              color={'#203152'}
              height={'10%'}
              width={'10%'}
              className="rloader"
            />
            Creating profile...
          </div>
        ) : null}

      </form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);
SignUpFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
};
const SignUpForm = compose(
  withRouter,
  withFirebase,
  withStyles(useStyles),
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };