import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (authUser) {
            this.props.firebase.usersDB().where('userId', '==', authUser.uid).get().then((querySnapshot) => {
              //let users = [];
              //should be only one record for the user
              querySnapshot.forEach((doc) => {
                //merge auth and db user objects
                authUser = {
                  uid: authUser.uid,
                  email: authUser.email,
                  ...doc.data(),
                };
                this.setState({ authUser });
              });//end for each
            
            });
          } else {
            this.setState({authUser: null});
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;