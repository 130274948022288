import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
        apiKey: "AIzaSyD1F5NwYSC_2-Jtu-EI_wP5v4aE0-YkVaY",
        authDomain: "fir-auth-72540.firebaseapp.com",
        databaseURL: "https://fir-auth-72540.firebaseio.com",
        projectId: "fir-auth-72540",
        storageBucket: "fir-auth-72540.appspot.com",
        messagingSenderId: "433831590888",
        appId: "1:433831590888:web:2f40443d29b386bf"
      };
/*const config = {
    apiKey: "AIzaSyBtO-m0lp3xftKN0eAgjHUQdBHAcyFqH_k",
    authDomain: "dde-staging.firebaseapp.com",
    databaseURL: "https://dde-staging.firebaseio.com",
    projectId: "dde-staging",
    storageBucket: "dde-staging.appspot.com",
    messagingSenderId: "75151397010",
    appId: "1:75151397010:web:a6ee49b6ac69e7cab8164b",
    measurementId: "G-1XZ4LHH8GP"
};*/

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.firestore();
        this.files = app.storage();
    }

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    fileStorage = () => this.files.ref();
    //users
    usersDB = () => this.db.collection('users');
    //Images
    imagesDB = () => this.db.collection('images');
    //Documents
    documentsDB = () => this.db.collection('documents');
    //messages
    msgDB = () => this.db.collection('messages');
    msgStore = () => this.files.ref();
    //cases
    caseDB = () =>this.db.collection('cases');
    //notifications
    notificationsDB = () => this.db.collection('notifications');
    //notifications
    newsletterDB = () => this.db.collection('newsletter-subscribers');
    //chatroom
    chatroomDB = () => this.db.collection('chatroom');
    //content
    contentDB = () => this.db.collection('content-pages');
    //training log
    traingLogDB = () => this.db.collection('training-logs');
    //training log
    availabilityDB = () => this.db.collection('availability');
    //account request
    accountRequestDB = () => this.db.collection('account-requests');
    //services
    servicesDB = () => this.db.collection('services');
}

export default Firebase;