import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { AppString } from './../../components/Const';
import { withFirebase } from '../Firebase';
import firebase from 'firebase';
import { Button, TextField, Tooltip } from '@material-ui/core';
import images from './../../images/Images';
import { toast } from 'react-toastify';

import './profile.css';
import 'react-toastify/dist/ReactToastify.css';

const INITIAL_STATE = {
  userName: localStorage.getItem(AppString.NICKNAME),
  aboutMe: localStorage.getItem(AppString.ABOUT_ME),
  error: null,
};

class EditProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE,
      id: localStorage.getItem(AppString.ID),
      nickname: localStorage.getItem(AppString.NICKNAME),
      aboutMe: localStorage.getItem(AppString.ABOUT_ME)!=="undefined" ? localStorage.getItem(AppString.ABOUT_ME) : '',
      photoUrl: localStorage.getItem(AppString.PHOTO_URL)!=="undefined" ? localStorage.getItem(AppString.PHOTO_URL) : '',
      userName: localStorage.getItem(AppString.NICKNAME),
      unavailability_reason: "",
      userRole: localStorage.getItem('userRole'),
      unavailable: false,
      address: '',
      city: '',
      state: '',
      postalCode: '',
      status: '',
      hideAccount: ''
    };

  }

  onSubmit = event => {
    const { userName } = this.state;

    this.props.firebase
      .doPasswordUpdate(userName)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  componentDidMount() {
    const that = this;
    this.props.firebase.usersDB().where("userId", "==", this.state.id)
      .get({source:'server'})
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              //console.log(doc.data())
              that.setState({
                unavailable: doc.data().isUnavailable,
                unavailability_reason: doc.data().unavailability_reason,
                address: doc.data().address,
                city: doc.data().city,
                state: doc.data().state,
                postalCode: doc.data().postalCode,
                status: doc.data().status,
                hideAccount: doc.data().hideAccount,
              })
          })
      })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChecked = event => {
    this.setState({ [event.target.name]: event.target.checked });
  }

  onChangeAvatar = event => {
    if (event.target.files && event.target.files[0]) {
      // Check this file is an image?
      const prefixFiletype = event.target.files[0].type.toString()
      if (prefixFiletype.indexOf(AppString.PREFIX_IMAGE) !== 0) {
        //this.props.showToast(0, 'This file is not an image')
        this.setState({ error : {message : 'This file is not an image' }})
        //console.log(this.state.error)
        return
      }else{
        this.setState({ error : null})
      }
      this.newAvatar = event.target.files[0]
      this.setState({ photoUrl: URL.createObjectURL(event.target.files[0]), image_name : event.target.files[0].name })
    } else {
      //this.props.showToast(0, 'Something wrong with input file')
      this.setState({ error: 'Something wrong with input file' });
    }
  }

  uploadAvatar = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true })
    if (this.newAvatar) {
      // const uploadTask = myStorage
      //   .ref()
      //   .child(this.state.id)
      //   .put(this.newAvatar)

      var uploadTask = this.props.firebase.fileStorage()
      .child(this.state.id)
      .put(this.newAvatar)

      uploadTask.on(
        AppString.UPLOAD_CHANGED,
        null,
        err => {
          //this.props.showToast(0, err.message)
          console.log(err.message);
          toast(err.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
              type: 'error',
          });
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.updateUserInfo(true, downloadURL)
          })
        }
      )
    } else {
      this.updateUserInfo(false, null)
    }
  }

  updateUserInfo = (isUpdatePhotoUrl, downloadURL) => {
    let that =this;
    let unavailability_reason, newInfo, hideAccount;

    if(this.state.unavailable===true){
        unavailability_reason = this.state.unavailability_reason;
    }else{
        unavailability_reason = "";
    }

    if(this.state.hideAccount){
        hideAccount = 1;
    }else{
        hideAccount = 0;
    }

    //console.log(this.state)

    if (isUpdatePhotoUrl) {
      newInfo = {
        username: this.state.nickname,
        aboutMe: this.state.aboutMe,
        photoUrl: downloadURL,
        displayName: this.state.nickname,
        isUnavailable: this.state.unavailable ? this.state.unavailable : '',
        unavailability_reason: unavailability_reason,
        address: this.state.address ? this.state.address : '',
        city: this.state.city ? this.state.city : '',
        state: this.state.state ? this.state.state : '',
        postalCode: this.state.postalCode ? this.state.postalCode : '',
        status: this.state.status ? this.state.status : '',
        hideAccount: hideAccount
      }
    } else {
      newInfo = {
        username: this.state.nickname,
        aboutMe: this.state.aboutMe,
        displayName: this.state.nickname,
        isUnavailable: this.state.unavailable ? this.state.unavailable : '',
        unavailability_reason: unavailability_reason,
        address: this.state.address ? this.state.address : '',
        city: this.state.city ? this.state.city : '',
        state: this.state.state ? this.state.state : '',
        postalCode: this.state.postalCode ? this.state.postalCode : '',
        status: this.state.status ? this.state.status : '',
        hideAccount: hideAccount
      }
    }

    var user = firebase.auth().currentUser;
    user.updateProfile(newInfo).then(function() {
      // Update successful.
      localStorage.setItem(AppString.NICKNAME, that.state.nickname)
      localStorage.setItem(AppString.ABOUT_ME, that.state.aboutMe)
      if (isUpdatePhotoUrl) {
        localStorage.setItem(AppString.PHOTO_URL, downloadURL)
      }
      
    }).catch(function(error) {
      // An error happened.
      console.log(error)
    });

    //console.log(newInfo)

    this.props.firebase.usersDB().where("userId", "==", this.state.id)
      .get({source:'server'})
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
              //console.log(doc.id, " => ", doc.data());

              that.props.firebase.usersDB()
                .doc(doc.id)
                .update(newInfo)
                .then(data => {
                  //console.log('updated')
                  that.setState({ isLoading: false });
                  toast('Profile successfully updated',
                    { 
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: 3000, 
                      type: 'success',
                  });
                })
          });
      })
      .catch(function(error) {
          that.setState({ isLoading: false });
          console.log("Error getting documents: ", error);
          toast('Error updating profile. Please try again.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                      type: 'error',
                  });
      });
  }


  render() {
    const { userName, error } = this.state;
    //console.log(this.state)
    const isInvalid =
       userName === '' || error!==null;

    return (
      <form onSubmit={this.uploadAvatar}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="userName"
            label="User Name"
            type="text"
            id="userName"
            value={this.state.userName ? this.state.userName : ''}
            onChange={this.onChange}
          />
        
          <TextField
            multiline
            rowsMax="4"
            variant="outlined"
            margin="normal"
            
            fullWidth
            name="aboutMe"
            label="About Me"
            type="text"
            id="aboutMe"
			className="aboutMe"
            value={this.state.aboutMe ? this.state.aboutMe : ''}
            onChange={this.onChange}
          />

          {
            (this.state.userRole==="Mill" || this.state.userRole==="Designer")
            ? <div>
              <label>
                <input type="checkbox" checked={this.state.unavailable} name="unavailable" value="1" onChange={this.onChecked} />
                <Tooltip title="You will be listed on site but unavailable for selection" placement="top">
                  <span>Mark yourself unavailable</span>
                </Tooltip>
              </label>
                {
                  this.state.unavailable===true
                  ? <TextField
                      multiline
                      rowsMax="4"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="unavailability_reason"
                      label="Reason for unavailability"
                      type="text"
                      id="unavailability_reason"
                      className="unavailability_reason"
                      value={this.state.unavailability_reason ? this.state.unavailability_reason : ''}
                      onChange={this.onChange}
                    />
                  : ''
                }
                <label>
                  <input type="checkbox" checked={this.state.hideAccount} name="hideAccount" value="1" onChange={this.onChecked} />
                  <Tooltip title="Completely hide yourself from listings" placement="top">
                    <span>Hide yourself</span>
                  </Tooltip>
                </label>
            </div>
            : ''
          }

          {
            (this.state.userRole==="Denturist")
            ? <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="address"
                  label="Address"
                  type="text"
                  id="address"
                  className="address"
                  value={this.state.address ? this.state.address : ''}
                  onChange={this.onChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="city"
                  label="City"
                  type="text"
                  id="city"
                  className="city"
                  value={this.state.city ? this.state.city : ''}
                  onChange={this.onChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="state"
                  label="State"
                  type="text"
                  id="state"
                  className="state"
                  value={this.state.state ? this.state.state : ''}
                  onChange={this.onChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="postalCode"
                  label="Zip Code"
                  type="text"
                  id="postalCode"
                  className="postalCode"
                  value={this.state.postalCode ? this.state.postalCode : ''}
                  onChange={this.onChange}
                />
                  
            </div>
            : ''
          }
            


          <div className="viewWrapInputFile" style={{marginTop:'20px'}}>
            <div className="imgWrapper">
            <img
              className="imgInputFile"
              alt="icon gallery"
              src={images.ic_input_file}
              onClick={() => this.refInput.click()}
            />
            <input
              ref={el => {
                this.refInput = el
              }}
              accept="image/*"
              className="viewInputFile"
              type="file"
              onChange={this.onChangeAvatar}
            />
            </div>
            <div className="imgName">{this.state.image_name}</div>
          </div>


          

        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isInvalid}
          >
          Update Profile
        </Button>

        {error && <p>{error.message}</p>}
        {this.state.isLoading ? (
          <div className="viewLoading">
            <ReactLoading
              type={'spin'}
              color={'#203152'}
              height={'10%'}
              width={'10%'}
              className="rloader"
            />
            Updating profile...
          </div>
        ) : null}
      </form>
    );
  }
}

export default withFirebase(EditProfileForm);