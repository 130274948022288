import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { TextField, Button } from '@material-ui/core';
import firebase from 'firebase';
import {withRouter} from 'react-router-dom';
import { AppString } from './../../components/Const';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import { withFirebase } from './../Firebase';
import ReactLoading from 'react-loading';
import moment from 'moment';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class LandingPage extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, 
      email_address : '', 
      error : false, 
      errorText : '',
      isLoading: false
    };

  }

  componentDidMount() {
    document.title = 'Digital Denture Experts';
  }

  submitNewsletter = event => {
    const emailValue = this.state.email_address;
    const emailValid = emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    //console.log(emailValid);

    if(this.state.email_address==='' || emailValid===null){
      this.setState({
        error: true,
        errorText : 'Please enter a valid email e.g. johndoe@example.com'
      });
    }else{

      this.setState({
        error: false,
        errorText : ''
      });

      const email_address = this.state.email_address;
      const add_date = firebase.firestore.Timestamp.fromMillis(moment());
      const status = 1;
      this.props.firebase.newsletterDB().add({
            email_address,
            add_date,
            status
        }).then((docRef) => {
              this.setState({
                email_address: '',
              });
              toast("Successfully subscribed to receive our Newsletter", { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                  type: 'success', onClose: () => { this.props.history.push(ROUTES.LANDING) }
              });
        }).catch((error) => {
              console.error("Error adding document: ", error);
              toast("Some technical error occured, please try again.", { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                  type: 'error', onClose: () => { this.props.history.push(ROUTES.LANDING) }
              });
        });
    }
  }

  onSubmit = event => {
    event.preventDefault();  
    const { email, password } = this.state;
    this.setState({ isLoading : true });
    const that = this;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        firebase.auth().onAuthStateChanged((thisUser) => {
          //console.log(thisUser);
          if(thisUser){
            this.props.firebase.usersDB().where("userId", "==", thisUser.uid)
                .get({source:'server'})
                .then(function(querySnapshot) {
                    that.setState({ isLoading : false });
                   
                    querySnapshot.forEach(function(doc) {
                        let user = doc.data();
                        if(user.status===0){
                          toast('Your account is not active. Please contact administartor for details', 
                            { 
                              position: toast.POSITION.TOP_CENTER, 
                              autoClose: 2000,
                              type: 'error'
                            });
                        }else{
                          localStorage.setItem(AppString.ID, user.userId)
                          localStorage.setItem(AppString.NICKNAME, user.username)
                          localStorage.setItem(AppString.USER_ROLE, user.userRole)
                          localStorage.setItem(AppString.PHOTO_URL, user.photoUrl)
                          localStorage.setItem(AppString.ABOUT_ME, user.aboutMe)
                          toast('Logging you in...', 
                              { 
                                position: toast.POSITION.TOP_CENTER, 
                                autoClose: 2000,
                                type: 'success', 
                                onClose: () => {
                                  that.props.history.push(ROUTES.HOME); 
                                  that.setState({ ...INITIAL_STATE }); 
                                  window.location.reload(); 
                                }
                          });
                        }
                    });
                  
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                });
            }
        });
        
        
      })
      .catch(error => {
        this.setState({ error : error, isLoading : false });
         toast(error.message, { draggable:false,position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'error',
      });
      });

    
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


render() {
  const { email, password } = this.state;

  const isInvalid = password === '' || email === '';

  return (
  <div>
      <div className="banner-bg">
<div className="home-banner">

  <div className="banner-img"><img src="/images/home-banner.jpg" alt="" /> </div>
  <div className="caption">
    <div className="container">
      <div className="row">
        <div className="col s12 box m8 l7">

          <div className="grow-business">
  
    
      <h2> Grow your Denture Business<span>By Helping Serve More Patients.</span></h2>
      <div className="col s12 m4">
        <div className="icon-block">
          <div className="photo center"> <img src="/images/cad-design.png" alt=""/></div>
          <h4 className="center">Cad design<br/>
            by real denturists</h4>
        </div>
      </div>
      <div className="col s12 m4">
        <div className="icon-block">
          <div className="photo center"><i><img src="/images/grow-arrow01.png" alt=""/></i><img src="/images/network.png" alt=""/></div>
          <h4 className="center">Training + Support</h4>
        </div>
      </div>
      <div className="col s12 m4">
        <div className="icon-block">
          <div className="photo center"><i><img src="/images/grow-arrow02.png" alt=""/></i><img src="/images/training.png" alt=""/></div>
          <h4 className="center">Network of mills</h4>
        </div>
      </div>
   
</div>
         
        </div>
        {
          (localStorage.getItem('id')==="" || localStorage.getItem('id')===null)
          ? <div className="col right box s12 m4 l4">
          <div className="login-account white">
            <div className="row">
              <h3>Login to your Account</h3>

              <form noValidate onSubmit={this.onSubmit}>
              <div className="input-field col s12">
        <TextField
            margin="none"
            required
            fullWidth={true}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={this.onChange}
          />
          </div>
          <div className="input-field col s12">
          <TextField
            margin="none"
            required
            fullWidth={true}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={this.onChange}
          />
          </div>
          <div className="input-field col s12  check">
                <label>
                  <input type="checkbox" />
                  <span>Remember me</span> 
                </label>
              </div>
          <div className="input-field col s12">

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isInvalid}
            className="btn waves-effect waves-light"
          >
                {this.state.isLoading ? (
                <div>
                  <ReactLoading
                    type={'spin'}
                    color={'#fff'}
                    height={'15%'}
                    width={'15%'}
                    className="tstmenu tst_loaded"
                  />
                   Processing
                </div>
              ) : 'Sign In'}
                  
                </Button>

          </div>
          {/*error && <p>{error.message}</p>*/}
        </form>


              
            </div>
            <div style={{fontSize:'14px', padding: '10px',textAlign: 'center',color:'#004452'}}>
            <Link style={{color:'#004452'}} to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
            </div>
          </div>
        </div>
        : ''
      }


      </div>
    </div>
  </div>
</div>
</div>
  
  {/*<div className="section grow-business">
  <div className="container">
    <div className="row">
      <h2> <i><img src="/images/business-doller.png" alt="doller" /></i> Grow your Denture Business<span>By Helping Serve More Patients.</span></h2>
      <div className="col s12 m4">
        <div className="icon-block">
          <div className="photo center"> <img src="/images/cad-design.png" alt=""/></div>
          <h4 className="center">Cad design<br />
            by real dentursits</h4>
          <p className="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
      </div>
      <div className="col s12 m4">
        <div className="icon-block">
          <div className="photo center"><i><img src="/images/grow-arrow01.png" alt=""/></i><img src="/images/network.png" alt=""/></div>
          <h4 className="center">Training + Support</h4>
          <p className="center">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
      <div className="col s12 m4">
        <div className="icon-block">
          <div className="photo center"><i><img src="/images/grow-arrow02.png" alt=""/></i><img src="/images/training.png" alt=""/></div>
          <h4 className="center">Network of mills</h4>
          <p className="center">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>
      </div>
    </div>
  </div>
</div>*/}

<div className="section subscribe-newsletter">
  <div className="container">
    <h2>Helping you design success !</h2>
    <p>Simplify the complex world of digital denture futures</p>
    <div className="input-field">
    <p>
      <a rel="noopener noreferrer" href="https://digitaldentureexperts.com/contact-us" target="_blank" className="btn btn-orange round-btn">contact administrator</a>
    </p>
      {/*<input 
              name="email_address" 
              value={this.state.email_address} 
              id="email_address" 
              onChange={this.onChange} 
              placeholder="Email Address" 
              type="text" 
              className="validate" 
            />
            <input name="" type="submit" value="Subscribe" className="btn" onClick={this.submitNewsletter} />*/}
    </div>
    <div className="error" style={{ color: '#ff0000'}}>{this.state.errorText}</div>
  </div>
</div>

  </div>
  );
}
}

export default compose(withRouter, withFirebase)(LandingPage);