import React, {Component} from 'react';
//import firebase from 'firebase';
import {withRouter} from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from './../Firebase';

class Content extends Component {

  constructor(props) {
    super(props);

    this.state = { 
      page_title : '', 
      page_content : ''
    };

  }

  componentDidMount() {
    document.title = 'Digital Denture Experts';
    console.log(this.props.match.params.id)
    this.props.firebase.contentDB().where("slug", "==", this.props.match.params.id).get({ source: 'server' }).then((querySnapshot) => {
      querySnapshot.forEach((des) => {
        this.setState({
          page_title : des.data().page_title,
          page_content : des.data().page_content,
        })
      });

    });
  }


render() {
  const { page_title, page_content } = this.state;

  return (
  <div>
 <div className="section">
  <div className="container">
    <h2>{page_title}</h2>
    <div dangerouslySetInnerHTML={{ __html: page_content }} />
  </div>
</div>

  </div>
  );
}
}

export default compose(withRouter, withFirebase)(Content);