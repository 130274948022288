import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import {withStyles, Paper, Table, TableBody, TableHead,TableRow, TableCell} from '@material-ui/core';
import { compose } from "recompose";
import moment from 'moment';
//import FormattedDate from './../Utils/FormattedDate';

const useStyles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  });


class DocumentList extends Component {
constructor(props) {
    super(props);
    this.state = {
      docs: []
    }
    
}
//const classes = useStyles();
fetchRecord(){
  //console.log("in====")
  this.setState({ loading: true });
const docRef = this.props.firebase.documentsDB().where('job', '==', this.props.jobId);
docRef.get({source:'server'}).then((querySnapshot) => {
    
    let docs = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if (doc.data().filetype !== 'images') {
        docs.push(doc);
      }
    });
    this.setState({
      docs: docs,
      loading: false,
    });
  });
}

fetchNewRecord(){
  //console.log("in====")
  this.setState({ loading: true });
const docRef = this.props.firebase.documentsDB().where('job', '==', this.props.jobId);
docRef.get({source:'server'}).then((querySnapshot) => {
    
    let docs = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if (doc.data().filetype !== 'images') {
        docs.push(doc);
      }
    });
    /*this.setState({
      docs: docs,
      loading: false,
    });*/
      this.setState({ 
        docs: docs,
        loading: false, 
        }, function () {
           this.fetchRecord()
      });
  });
}

componentDidMount() {

  this.fetchRecord()
}

  componentWillUnmount() {
    if (this.removeListener) {
      this.removeListener();
    }
  }

  /*componentWillReceiveProps(props) {
  const { refresh } = this.props;
  //console.log(this.props.refresh+'===='+refresh);
  if (props.refresh) {
    this.fetchRecord()
  }
}*/
componentWillReceiveProps(props) {
  //const { refresh } = this.props;
  //console.log(props);
  //if (props.refresh) {
    this.fetchNewRecord()
  //}
}


render() {
  const {classes} = this.props;
    return (
        <div className={classes.root+' doc_table'}>
      <Paper className={classes.paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">By</TableCell>
              <TableCell align="center">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.docs.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.data().filename}
                </TableCell>
                <TableCell align="center">
                  {/*row.data().datetime.seconds*/}
                  {moment.unix(row.data().datetime.seconds).format('MMM DD, YYYY')}
                </TableCell>
                <TableCell align="center">{row.data().userName}</TableCell>
                <TableCell align="center"><a rel="noopener noreferrer" target="_blank" href={row.data().downloadUrl}>Download</a></TableCell>
              </TableRow>
            ))}
          </TableBody>
              </Table>
              </Paper>
    </div>
    );
}
}
DocumentList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withStyles(useStyles), withFirebase)(DocumentList);