import React, { Component} from 'react';
//import { Link } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import { compose } from "recompose";

class Footer extends Component {
  
render() {
  return (
  <footer className="page-footer white">
  <div className="footer-copyright white">
    <div className="container">
      <div className="row">
        <div className="col s12 m6">
          <p>Copyright &copy; 2019 Digital Denture Experts - All Rights Reserved.</p>
        </div>
        <div className="col s12 m6 right">
          <p>Website Design by Nirvana Canada</p>
        </div>
      </div>
    </div>
  </div>
</footer>
);
  }
}

export default compose(withRouter )(Footer);
//export default withRouter(Footer);