import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withFirebase } from './../Firebase';
import firebase from 'firebase';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Breadcrumbs, TextField, Paper, Typography, Button, Container, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import * as ROUTES from './../../constants/routes';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import './bootstrap.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
//import EmailService from './EmailService';


const useStyles = (theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fullWidth: true,
    },
    formControl: {
        margin: 5,
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap'
      },
}));
        
class CaseCreate extends Component {

    constructor(props) {
        super(props);
        this.removeSlot = this.removeSlot.bind(this);
        const parsed = queryString.parse(this.props.location.search);
        this.state = {
            Title: '',
            Description: '',
            Date: firebase.firestore.Timestamp.fromMillis(moment().valueOf()),
            DisplayDate:moment(),
            users:[],
            mill:'',
            denturist:'',
            designer:'',
            millsList:[],
            designersList:[],
            status: '',
            lastUpdated: '',
            newCaseId: '',
            value: parsed.job_type,
            currentPage: 1,
          	itemsPerPage : 100,
          	docs: [],
          	show_check: false,
          	cloned_job_id:'',
          	copy_files: false,
          	copy_msg: false,
          	documents: [],
            designerName: '',
            millName: '',
            HoursPurchased: '',
            events: [],
            trainingStartDate: '',
            trainingEndDate: '',
            selectedTimeSlots: [],
            timeSlotIds:[]
        };

        const userRole = localStorage.getItem('userRole');
        if(userRole!=="Denturist"){
            this.props.history.push(ROUTES.HOME);
            toast('Please logged in as Denturist to post a job', 
              { position: toast.POSITION.TOP_CENTER, autoClose: 3000, type: 'error' });
        }
        //this.emailService = new EmailService();

    }
      
 componentDidMount() {
  
  
 	//let formData = "s";
 	//this.emailService.save(formData)
  document.title = 'Add New Job - Digital Denture Experts'
    firebase.auth().onAuthStateChanged((thisUser) => {
        if(thisUser){
            this.setState({users:[thisUser.uid,]});
            this.setState({denturist:thisUser.uid});
           if(thisUser.userRole === "Denturist") {
                this.setState({denturist:thisUser.uid});
            }
            if(thisUser.userRole === "Mill") {
               this.setState({mill:thisUser.uid});
           }
           if(thisUser.userRole === "Designer") {
               this.setState({designer:thisUser.uid});
           }
           this.props.firebase.usersDB().where("userRole", "==", "Mill").where("status", "==", 1).where("hideAccount", "==", 0).get({source:'server'}).then((querySnapshot) => {
               let millsList = [];
               querySnapshot.forEach((doc) => {
                     millsList.push({...doc.data(), id: doc.id});
               });
               this.setState({
                 millsList,
               });
             });
             this.props.firebase.usersDB().where("userRole", "==", "Designer").where("status", "==", 1).where("hideAccount", "==", 0).get({source:'server'}).then((querySnapshot) => {
               let designersList = [];
               querySnapshot.forEach((doc) => {
                     designersList.push({...doc.data(), id: doc.id});
               });
               this.setState({
                 designersList,
               });
             });
        } else {
          this.setState({loggedIn: false})
        }
    })

    this.getCases();
 }

  handleDateClick = (info) => { // bind with an arrow function
    //console.log(info)
    alert('Please select available timeslots to book');
    this.setState({ 
        trainingEndDate : info.dateStr, 
        trainingStartDate : info.dateStr, 
    });
  }

  handleEventClick = (info) => {
    const selectedTimeSlots = this.state.selectedTimeSlots;
    const start = moment(info.event.start).format('YYYY-MM-DD hh:mm A');
    const end = moment(info.event.end).format('YYYY-MM-DD hh:mm A');
    const str = start+' - '+end;
    selectedTimeSlots.push(str);
    var unique = selectedTimeSlots.filter( this.onlyUnique );

    const timeSlotIds = this.state.timeSlotIds;
    timeSlotIds.push(info.event.id);
    var uniqueIds = timeSlotIds.filter( this.onlyUnique );

    this.setState({ selectedTimeSlots:unique, timeSlotIds: uniqueIds, trainingEndDate : end, trainingStartDate : start })
  }

  onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
  }

  removeSlot(event){
    event.preventDefault();
    const selectedTimeSlots = this.state.selectedTimeSlots;
    let index = selectedTimeSlots.indexOf(+event.target.id)
    selectedTimeSlots.splice(index, 1)

    this.setState({ selectedTimeSlots : selectedTimeSlots });
    
  }

  handleRender = (ev) => {
    console.log(ev.el)
  }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onHrsChange = event => {
        var vals = event.target.value;
        var v = '';
        if(isNaN(vals)){
          v = '';
        }else{
          v = vals;
        }
        this.setState({ [event.target.name]: v });
    };

    onMillChange = event => {
    var vals = event.target.value;
    var res = vals.split("|");
    console.log(res[0])
    this.setState({mill:res[0], millName: res[1],users: [], designer:'',designerName:'',selectedTimeSlots:[]});
  };
  onDesignerChange = event => {
    var vals = event.target.value;
    var res = vals.split("|");
    this.getAvailability(res[0])
    this.setState({designer:res[0], designerName:res[1], users: [], mill:'', millName: '',events:[],selectedTimeSlots:[] });
  };

  handleDateSelect = (event) => {
    //console.log(event.endStr)
    this.setState({ 
        trainingEndDate : event.endStr, 
        trainingStartDate : event.startStr, 
    });
  }

  getAvailability(userId){
    const that = this;
    this.props.firebase.availabilityDB().where("userId", "==", userId).where("markBooked", "==", false)
      .get({source:'server'})
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              //console.log(doc.data());
              that.setState({  // add new event data
                events: that.state.events.concat({ // creates a new array
                  id: doc.id,
                  title: doc.data().title,
                  start: doc.data().start,
                  end: doc.data().end,
                  date: doc.data().start+' - '+doc.data().end
                })
              })
              
          })
      })
  }

    onJobChange = event => {
        this.setState({ [event.target.name]: event.target.value, show_check: true });
    };

    onDateChange = event => {
        this.setState({ Date: firebase.firestore.Timestamp.fromMillis(event.valueOf())});
        this.setState({ DisplayDate: event.valueOf()});
    }

    cloneDocuments(job_id, new_job_id){
	  //console.log("in====")
		const docRef = this.props.firebase.documentsDB().where('job', '==', job_id);
		docRef.get({source:'server'}).then((querySnapshot) => {
		    
		    //let docs = [];
		    querySnapshot.forEach((doc) => {
		        //docs.push(doc);
		        var dbRef = (doc.data().filetype === 'images')?this.props.firebase.imagesDB():this.props.firebase.documentsDB();
		        dbRef.add({
                filename: doc.data().filename,
                filetype: doc.data().filetype,
                userID: doc.data().userID,
                userName: doc.data().userName,
                downloadUrl: doc.data().downloadUrl,
                datetime: new Date(),
                job: new_job_id
          	})
		    });
	  });
	}
    onSubmit = (e) => {
      e.preventDefault();
      if(this.state.cloned_job_id!==""){
      	this.setState({users:[]});
      }

      if(this.state.mill && this.state.mill.length > 0) {
          let users = this.state.users;
          users.push(this.state.mill);
          this.setState(users);
      }
      if(this.state.designer && this.state.designer.length > 0) {
          let users = this.state.users;
          users.push(this.state.designer);
          this.setState(users);
      }

      if(this.state.denturist.length > 0) {
      	let users = this.state.users;
      	users.push(this.state.denturist);
      	this.setState(users);
      }
      var u = [this.state.designer, this.state.mill, this.state.denturist]; 
      var filtered = u.filter(function (el) {
        return el !== "";
      });

      const that = this;
      var nfiltered = filtered.filter(function (elem) {
        return elem !== that.state.denturist;
      });
       
      const { Title, Description, Date, denturist, mill, designer, copy_files, cloned_job_id, designerName, millName, HoursPurchased, trainingStartDate, trainingEndDate } = this.state;
      const lastUpdated = firebase.firestore.Timestamp.fromMillis(moment());
      const status = "Posted";
      const jobType = [];
      const users = filtered;
      const denturistName = localStorage.getItem('nickname');
	    if(designer!=="" && HoursPurchased===''){
	      jobType.push('Designing Jobs');
	    }
	    if(mill!==""){
	      jobType.push('Milling Jobs');
	    }
      if(HoursPurchased!==""){
        jobType.push('Training');
      }
        var doc_id = '';
        this.props.firebase.caseDB().add({
            Title,
            Description,
            Date,
            users,
            denturist,
            denturistName,
            mill,
            millName,
            designer,
            designerName,
            status,
            jobType,
            lastUpdated,
            HoursPurchased,
            trainingStartDate,
            trainingEndDate
        }).then((docRef) => {
              doc_id = docRef.id;

              if(copy_files===true){
				          this.cloneDocuments(cloned_job_id, doc_id);
              }
             this.setState({
                Title: '',
                Description: '',
                DisplayDate: moment(),
                users:[],
                denturist:'',
                mill:'',
                designer:'',
                HoursPurchased: '',
                newCaseId: docRef.id,
                trainingStartDate: '',
                trainingEndDate: ''
            });

             if(status==="Posted" && (mill!=="" || designer!=="")){

              for( var i = 0; i < users.length; i++){ 
                 if(users[i] === this.state.denturist) {
                    users.splice(i, 1); 
                 }
              }

              

              //nfiltered.forEach((mil) => {
                //if(mil!==denturist){
                    //const tu = [];
                    //tu.push(mil);
                    const addDate = Date;
                    const caseId = doc_id;
                    const fromUser = denturist;
                    const toUsers = nfiltered;
                    const isRead = false;
                    const nickname = localStorage.getItem('nickname');
                    const subject = "New case assigned";
                    const message = "New case assigned by "+nickname;
                    const jobStatus = "Assigned"
                    this.props.firebase.notificationsDB().add({ addDate,caseId,fromUser,toUsers,isRead,subject,message,jobStatus}).then(() => {
                          //console.log('tets')
                    })
                  //}
                //})
              }

              if(this.state.timeSlotIds.length>0){
                const markBooked = true;
                for (const [index, value] of this.state.timeSlotIds.entries()) {
                    console.log(index);
                    const ref = this.props.firebase.availabilityDB().doc(value);
                      ref.get().then((doc) => {
                          if (doc.exists) {

                              const {start, end, allDay, date, timeSlot, title, unavailability_reason, userId} = doc.data();
                              const bookedBy = denturist;
                              const bookedByUserName = denturistName;
                              const caseId = doc_id;
                              const updateRef = this.props.firebase.availabilityDB().doc(value);
                              updateRef.set({
                                start, 
                                end, 
                                allDay, 
                                date, 
                                markBooked, 
                                timeSlot, 
                                title, 
                                unavailability_reason, 
                                userId,
                                bookedBy,
                                caseId,
                                bookedByUserName
                              }).then((docRef) => {
                                 
                              }).catch((error) => {
                                  console.error("Error adding document: ", error);
                                  
                              });


                          }
                      })


                     
                }
              }

            this.props.history.push(ROUTES.CASE_DISPLAY + doc_id);
        }).catch((error) => {
              console.error("Error adding document: ", error);
        });
    }


    onLoadJob = (e) => {
    	e.preventDefault();
    	const { cloned_job_id } = this.state;
    	//console.log(cloned_job_id)
    	firebase.auth().onAuthStateChanged((thisUser) => {
        if(thisUser){
	    	const ref = this.props.firebase.caseDB().doc(cloned_job_id);
	        ref.get().then((doc) => {
	          if (doc.exists) {
	            const job = doc.data();
	            var jt = job.jobType;
	            var n = ''; 
	            if(jt.includes('Designing Jobs')){
	              n='Designing';
	            }
	            if(jt.includes('Milling Jobs')){
	              n='Milling';
	            }
              if(jt.includes('Training')){
                n='Training';
              }
	            this.setState({
	              key: cloned_job_id,
	              Title: job.Title,
	              Description: job.Description,
	              Date: firebase.firestore.Timestamp.fromMillis(moment()),
	              DisplayDate: moment(),
	              //users: job.users,
	              mill: job.mill,
	              status: job.status,
	              denturist: job.denturist,
	              designer: job.designer,
                denturistName: job.denturistName,
                designerName: job.designerName,
                millName: job.millName,
	              role:thisUser.userRole,
	              userId:thisUser.uid,
	              value:n,
	              users: []
	            });
	            this.props.firebase.usersDB().where("userRole", "==", "Mill").get({ source: 'server' }).then((querySnapshot) => {
	              let millsList = [];
	              querySnapshot.forEach((mil) => {
	                millsList.push({ ...mil.data(), id: mil.id });
	              });
	              this.setState({
	                millsList,
	              });
	            });
	            this.props.firebase.usersDB().where("userRole", "==", "Designer").get({ source: 'server' }).then((querySnapshot) => {
	              let designersList = [];
	              querySnapshot.forEach((des) => {
	                designersList.push({ ...des.data(), id: des.id });
	              });
	              this.setState({
	                designersList,
	              });
	            });
	          } else {
	            console.log("No such document!");
	          }
	        });
	    }
		})
    }

    getCases(){
	    const { itemsPerPage } = this.state;
	    //const startAt = currentPage * itemsPerPage - itemsPerPage;
	    let this_user;
	    firebase.auth().onAuthStateChanged((thisUser) => {
	        if(thisUser){
		    	this_user = thisUser.uid;
		    
	    //let userType = this.state.userRole;
	    //const that = this;

	    //const docRef= this.props.firebase.caseDB().where(userType.toLowerCase(), '==', thisUser);
	    let docRef = this.props.firebase.caseDB().where('denturist', '==', this_user);  
	   
	    docRef.orderBy('Date','desc').limit(itemsPerPage).get().then((querySnapshot) => {
	        let docs = [];
	        querySnapshot.forEach((doc) => {
	          docs.push({...doc.data(), id: doc.id });
	              
	        });
	        //console.log(this_user);
	        //console.log(docs);

	        this.setState({
	          docs,
	          loading: false,
	        });


	      });
	    }
		});
  	}
  

    handleChange = (event) => {
    	let designer, mill;
    	if(event.target.value==="Milling"){
    		designer=""
    	}else{
    		mill=""
    	}
	   this.setState({value:event.target.value, designer: designer, mill: mill, HoursPurchased:'', selectedTimeSlots:[]});
	}

	handleChecked = (event) => {
		this.setState({[event.target.name]:event.target.checked});
	}

    render() {
        const { classes } = this.props;
        const { Title, value, show_check, cloned_job_id, HoursPurchased, selectedTimeSlots } = this.state;
        var isInvalid;
        if(value==="Training"){
          isInvalid = Title === '' || value==='' || HoursPurchased==='';
        }else{
          isInvalid = Title === '' || value==='';  
        }
        
        const isCloned = cloned_job_id === '';
        return (
		<div>
		
                <Breadcrumbs className="nav-breadcrumbs"  separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                  <Link color="inherit" to={ROUTES.HOME}>
                    Jobs
                  </Link>
                  <Typography color="textPrimary">Post Job</Typography>
                </Breadcrumbs>
           
          <div className="container">
          <div className="common-box">
         
            <Container component="main" maxWidth="lg">
             <Grid container spacing={2}>
            <Grid className="job-dec box-shadow" item md={6} xs={12}> 
			<div className="sign-box case-create">
            <Paper>
              <Typography component="h1" variant="h5" color="primary">
               Create Job
               <Link to={ROUTES.HOME} className="btn btn-primary round-btn cancel"><CancelSharpIcon fontSize="small" className="icon-size"/> Cancel</Link>
              </Typography>

                    <form onSubmit={this.onSubmit} className={classes.container} noValidate>
                        <TextField
                            id="Title"
                            name="Title"
                            label="Title *"
                            style={{ margin: 8 }}
                            variant="outlined"
                            placeholder="Title"
                            helperText="Title is the case Id that you want to assign"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.Title}
                            onChange={this.onChange}
                        />

                        <TextField
                            id="Description"
                            name="Description"
                            label="Description"
                            variant="outlined"
                            helperText="You can add description for details"
                            multiline
                            fullWidth
                            style={{ margin: 8, width: '100%' }}
                            onChange={this.onChange}
                            className={classes.textField}
                            margin="normal"
                            value={this.state.Description}
                        />

                        <FormControl component="div" className={classes.formControl}>
                          <FormLabel component="label">Job Type *</FormLabel>
                          <RadioGroup
                            aria-label="Job Type"
                            name="gender1"
                            className='radio_job_type'
                            value={this.state.value}
                            onChange={this.handleChange}
                          >
                            <FormControlLabel value="Milling" control={<Radio color="default" />} label="Milling" />
                            <FormControlLabel value="Designing" control={<Radio color="default" />} label="Designing" />
                            <FormControlLabel value="Training" control={<Radio color="default" />} label="Training" />
                          </RadioGroup>
                        </FormControl>
                        {
                          this.state.value==="Milling"
                          ? <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="mill-id">Select a Mill</InputLabel>
                            <Select
                            native={true}
                            value={this.state.mill+'|'+this.state.millName}
                            onChange={this.onMillChange}
                            inputProps={{
                              name: 'mill',
                              id: 'mill-id',
                            }}
                          >
                              
                              {this.state.millsList.map(doc => (
                                  <option disabled={doc.isUnavailable}
                                   key={doc.id} value={doc.userId+'|'+doc.username}>{doc.username}
                                  	{ 
                                  		(doc.isUnavailable && doc.unavailability_reason && doc.unavailability_reason!=="" && doc.unavailability_reason!=="undefined")
                                  		? ' ('+doc.unavailability_reason+')'
                                  		: ''
                                  	}
                                  </option>
                              ))};
                          </Select>
                        </FormControl>
                        : ''
                      }
                      {
                        (this.state.value==="Designing" || this.state.value==="Training")
                        ? <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="designer-id">Select a Designer</InputLabel>
                          <Select
                            native={true}
                            value={this.state.designer+'|'+this.state.designerName}
                            onChange={this.onDesignerChange}
                            inputProps={{
                              name: 'designer',
                              id: 'designer-id',
                            }}
                          >
                            
                            {this.state.designersList.map(doc => (
                                <option  disabled={doc.isUnavailable} 
                                key={doc.id} 
                                value={doc.userId+'|'+doc.username}>{doc.username}
                                { 
                              		(doc.isUnavailable && doc.unavailability_reason && doc.unavailability_reason!=="" && doc.unavailability_reason!=="undefined")
                              		? ' ('+doc.unavailability_reason+')'
                              		: ''
                              	}
                                </option>
                            ))};
                          </Select>
                        </FormControl>
                        : ''
                      }

                      <FormControl className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker format='MMM DD, YYYY hh:mm A' name="DisplayDate" value={moment(this.state.DisplayDate)} onChange={this.onDateChange} />
                        </MuiPickersUtilsProvider></FormControl>

                        {
                          (this.state.value==="Training" && this.state.designer)
                          ? <div>
                            <Typography component="p" variant="body1">
                             Select available timeslots
                            </Typography>
                            <FullCalendar 
                              defaultView="dayGridMonth" 
                              plugins={[ dayGridPlugin, interactionPlugin ]}
                              dateClick={this.handleDateClick}
                              select={this.handleDateSelect}
                              events={this.state.events}
                              eventClick = {this.handleEventClick}
                              selectable = {true}
                              showNonCurrentDates = {false}
                            /></div>
                          : ''
                        }

                        <FormControl className={classes.formControl}>
                          {
                            (this.state.value==="Training" && selectedTimeSlots.length>0) &&
                            <p><label>Selected Timeslots</label></p>
                          }
                          { 
                            (this.state.value==="Training" && selectedTimeSlots.length>0) &&
                            selectedTimeSlots.map(doc => (
                              <Typography className="timeSlotList" key={doc} color="textPrimary">
                                {doc}
                                <a href="/" id={doc} onClick={this.removeSlot}>X</a>
                              </Typography>
                            ))
                          }
                        </FormControl>

                        <p>&nbsp;</p>
                        {
                        (this.state.value==="Training")
                        ? <TextField
                            id="HoursPurchased"
                            name="HoursPurchased"
                            label="# of Hrs Purchased *"
                            style={{ margin: 8 }}
                            variant="outlined"
                            placeholder=""
                            helperText="Enter only numeric value"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.HoursPurchased}
                            onChange={this.onHrsChange}
                        /> 
                        : ''

                        }
                        <p>&nbsp;</p>
                        
                        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isInvalid}
            >
                Submit </Button>
                    </form>
            </Paper></div>
            </Grid>






            <Grid className="job-dec box-shadow" item md={6} xs={12}> 
			<div className="sign-box case-create">
            <Paper>
              <Typography component="h1" variant="h5" color="primary">
               Clone Job
              </Typography>
              <Typography component="p" variant="body1">
               Select a job below and click 'Load Job' button to duplicate job. 
               This will load selected job details in left panel.
              </Typography>

                    <form onSubmit={this.onLoadJob} className={classes.container} noValidate>
                        
                    	<FormControl className={classes.formControl}>
                          <InputLabel htmlFor="mill-id">Select a Job</InputLabel>
                            <Select
                            value={this.state.cloned_job_id}
                            onChange={this.onJobChange}
                            inputProps={{
                              name: 'cloned_job_id',
                              id: 'cloned-job-id',
                            }}
                          >
                              {this.state.docs.map(doc => (
                                  <MenuItem key={doc.id} value={doc.id}>{doc.Title}</MenuItem>
                              ))};
                          </Select>
                        </FormControl>

                      
                        {
                        	show_check===true
                        	? <FormControl className={classes.formControl}>
					                <p><label>
					                  <input type="checkbox" name="copy_files" value="1" onChange={this.handleChecked} />
					                  <span>Do you want to copy the files?</span> 
					                </label></p>
					            
					                {/*<p><label>
					                					                  <input type="checkbox" name="copy_msg" value='1' onChange={this.handleChecked} />
					                					                  <span>Do you want to copy the conversation?</span> 
					                					                </label></p>*/}
					            </FormControl>
					        : ''
                        }
                        

                        <p>&nbsp;</p>
                        
                        <Button type="submit" fullWidth variant="contained" color="primary" disabled={isCloned}>
                        	Load Job 
                        </Button>
                    </form>
            </Paper>
            </div>
            </Grid>
            </Grid>
            </Container>
            </div>
			   </div>
			    </div>
        );
    }
}

CaseCreate.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withFirebase(withStyles(useStyles)(CaseCreate));