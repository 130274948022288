export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const CASE = '/case';
export const CASE_DISPLAY = '/case/CaseShow/';
export const CASE_EDIT = '/case/CaseEdit/';
export const CASE_CREATE = '/case/CaseCreate';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const AVAILABILITY = '/availability';
export const SERVICES = '/services';
export const JOB_TYPE = '/case/job-type';
export const SELECT_SERVICES = '/case/SelectServices';