import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import { TextField, FormControl, Button } from '@material-ui/core';
import moment from 'moment';
import firebase from 'firebase';
import { compose } from "recompose";
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
//import * as ROUTES from './../../constants/routes';
import 'react-image-lightbox/style.css';



class TrainingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TrainingDate: moment(),
      TrainingDetails: '',
      TimeUsed: '',
      TimeRemaining: '',
      add_date: firebase.firestore.Timestamp.fromMillis(moment().valueOf()),
      caseId: '',
      denturist: '',
      designer: '',
      HoursPurchased: '',
      time_used: 0
    }
  }
  //const classes = useStyles();
  onSubmit = (e) => {
      e.preventDefault();
      const { TimeRemaining, TimeUsed, TrainingDetails, denturist, designer, caseId } = this.state;
      const TrainingDate = firebase.firestore.Timestamp.fromMillis(this.state.TrainingDate);
      const add_date = firebase.firestore.Timestamp.fromMillis(moment());

        //var doc_id;
        this.props.firebase.traingLogDB().add({
            TimeRemaining,
            TimeUsed,
            TrainingDate,
            TrainingDetails,
            denturist,
            add_date,
            designer,
            caseId,
        }).then((docRef) => {
              //doc_id = docRef.id;

             this.setState({
                TimeRemaining: '',
                TimeUsed: '',
                TrainingDate: moment(),
                TrainingDetails:[],
                denturist:'',
                add_date:'',
                designer:'',
                caseId: '',
            });
             window.location.reload();
            //that.props.history.push(ROUTES.CASE_DISPLAY + doc_id);
        }).catch((error) => {
              console.error("Error adding document: ", error);
        });

  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    this.setState({
        caseId: this.props.caseId, 
        designer:this.props.designer, 
        denturist: this.props.denturist,
        HoursPurchased: this.props.HoursPurchased
    })

    this.fetchRecord()
  }

  componentWillUnmount() {
    if (this.removeListener) {
      this.removeListener();
    }
  }

  onDateChange = event => {
      this.setState({ TrainingDate: event.valueOf()});
  }

  onHrsChange = event => {
        var vals = event.target.value;
        var v = '';
        if(isNaN(vals)){
          v = '';
        }else{
          v = vals;
        }
        const rem_time = ((parseInt(this.state.HoursPurchased)*60)-parseInt(this.state.time_used))-parseInt(v);
        this.setState({ [event.target.name]: v, TimeRemaining: rem_time });
    };

  fetchRecord(){
    this.setState({ loading: true });
    const docRef = this.props.firebase.traingLogDB().where('caseId', '==', this.props.caseId).where('denturist', '==', this.props.denturist);
    docRef.get({source:'server'}).then((querySnapshot) => {
      
      let docs = [];
      let ttime = 0;
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        ttime= parseInt(ttime)+parseInt(doc.data().TimeUsed);
      });

      this.setState({
        docs: docs,
        time_used: ttime,
        loading: false,
      });
    });
  }


  render() {
   // console.log(this.props)
    const {TrainingDate,TrainingDetails, TimeRemaining , TimeUsed} = this.state;
    const isInvalid = TrainingDate === '' || TrainingDetails==='' || TimeRemaining==='' || TimeUsed==='';
    return (

        <form onSubmit={this.onSubmit} noValidate>
                            <FormControl>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <DateTimePicker 
                                    autoOk
                                    label="Training Date"
                                    inputVariant="outlined"
                                    format='MMM DD, YYYY hh:mm A' 
                                    name="TrainingDate" 
                                    value={moment(this.state.TrainingDate)} 
                                    onChange={this.onDateChange} 
                                  />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                            <TextField
                              multiline
                                id="TrainingDetails"
                                name="TrainingDetails"
                                label="Details *"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.TrainingDetails}
                                onChange={this.onChange}
                            />
                            <TextField
                                id="TimeUsed"
                                name="TimeUsed"
                                label="Time Used *"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                helperText="Enter time in minutes"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.TimeUsed}
                                onChange={this.onHrsChange}
                            />

                            <TextField
                                id="TimeRemaining"
                                name="TimeRemaining"
                                label="Time Remaining *"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                helperText="Enter time in minutes"
                                InputLabelProps={{
                                    shrink: true,
                                    readOnly: true,
                                }}
                                value={this.state.TimeRemaining}
                                onChange={this.onHrsChange}
                            />

                            <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isInvalid}
            >
                Submit </Button>
                    </form>
    );
  }
}


export default compose(withFirebase)(TrainingDetails);
//export default withStyles(useStyles)(ImageGallery);