import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Paper, Container, Button, TextField, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordForgetPage = () => (
  <div>
  
    <Container component="main" maxWidth="xs">
	<div className="sign-box">
      <Paper>
        <Typography component="h1" variant="h5" color="primary">
          Reset Password
        </Typography>
    <PasswordForgetForm />
    </Paper>
	</div>
	</Container>
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        toast('Please check your email for the instructions to reset your password.', { draggable:false,position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'success',
        });
      })
      .catch(error => {
        this.setState({ error });
        console.log(error)
        var msg="";
        if(error.code==="auth/user-not-found"){
          msg="No record found corresponding to this Email. Please check your email id and try again"
        }else{
          msg=error.message;
        }
        toast(msg, { draggable:false,position: toast.POSITION.TOP_CENTER, autoClose: 4000, 
            type: 'error',
        });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email } = this.state;

    const isInvalid = email === '';

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={this.state.email}
            onChange={this.onChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isInvalid}
          >
            Reset My Password
          </Button>
          {/*error && <p>{error.message}</p>*/}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };