import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from './../Firebase';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Grid, Breadcrumbs, Paper, Typography, Container } from '@material-ui/core';
import * as ROUTES from './../../constants/routes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
      
class CaseType extends Component {

    constructor(props) {
        super(props);

        const userRole = localStorage.getItem('userRole');
        if(userRole!=="Denturist"){
            this.props.history.push(ROUTES.HOME);
            toast('Please logged in as Denturist to post a job', 
              { position: toast.POSITION.TOP_CENTER, autoClose: 3000, type: 'error' });
        }
        //this.emailService = new EmailService();
    }
      
 componentDidMount() {
    document.title = 'Add New Job - Digital Denture Experts'
 }

    render() {
        return (
		      <div>
		
                <Breadcrumbs className="nav-breadcrumbs"  separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                  <Link color="inherit" to={ROUTES.HOME}>
                    Jobs
                  </Link>
                  <Typography color="textPrimary">Select Job Type</Typography>
                </Breadcrumbs>
           
          <div className="container">
          <div className="common-box">
            <Typography component="h1" variant="h5">
               Select a Job Type
              </Typography>
            <Container component="main" maxWidth="lg" style={{ marginTop:'20px' }}>
             <Grid container spacing={2}>
              <Grid className="job-dec box-shadow" item md={4} xs={12}> 
		             <div className="sign-box case-create">
                  <Paper>
                  <Link to={ROUTES.SELECT_SERVICES+'/?job_type=Designing'}>
                    <div className="icon-block">
                        <div className="photo center"> 
                            <img src="/images/cad-design.png" alt=""/>
                        </div>
                        <h4 className="center">CAD Design</h4>
                    </div>
                  </Link>
                  </Paper>
                </div>
              </Grid>
            <Grid className="job-dec box-shadow" item md={4} xs={12}> 
			        <div className="sign-box case-create">
                <Paper>
                <Link to={ROUTES.SELECT_SERVICES+'/?job_type=Milling'}>
                  <div className="icon-block">
                    <div className="photo center">
                        <img src="/images/network.png" alt=""/>
                    </div>
                    <h4 className="center">Mill Job</h4>
                  </div>
                </Link>
                </Paper>
              </div>
            </Grid>

            <Grid className="job-dec box-shadow" item md={4} xs={12}> 
              <div className="sign-box case-create">
                <Paper>
                <Link to={ROUTES.SELECT_SERVICES+'/?job_type=Training'}>
                  <div className="icon-block">
                    <div className="photo center">
                        <img src="/images/training.png" alt=""/>
                    </div>
                    <h4 className="center">Training</h4>
                  </div>
                </Link>
                </Paper>
              </div>
            </Grid>


            </Grid>
            </Container>
            </div>
			   </div>
			    </div>
        );
    }
}


export default withFirebase(CaseType);