import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import {withStyles, Paper, Table, TableBody, TableHead,TableRow, TableCell, Tooltip, TableFooter} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DeleteOutline } from '@material-ui/icons';
import { compose } from "recompose";
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactLoading from 'react-loading';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import FormattedDate from './../Utils/FormattedDate';

const useStyles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  });


class TrainingLog extends Component {
constructor(props) {
    super(props);
    this.state = {
      docs: [],
      deleteJobId: '',
      userRole: localStorage.getItem('userRole'),
      open: false,
      ttime: 0,
      total_remaining: 0
    }
    
}
//const classes = useStyles();
fetchRecord(){
  this.setState({ loading: true });
  const docRef = this.props.firebase.traingLogDB().where('caseId', '==', this.props.jobID).where('denturist', '==', this.props.denturist);
  docRef.orderBy('TimeRemaining','desc').orderBy('add_date','desc').get({source:'server'}).then((querySnapshot) => {
    
    let docs = [];
    let ttime = 0;
    let total_remaining = 0;
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if (doc.data().filetype !== 'images') {
        docs.push(doc);
      }
      ttime= parseInt(ttime)+parseInt(doc.data().TimeUsed);
    });

    total_remaining = (parseInt(this.props.HoursPurchased)*60)-parseInt(ttime);

    this.setState({
      docs: docs,
      loading: false,
      ttime : ttime,
      total_remaining: total_remaining
    });
  });
}

handleClickOpen = (id,tes, e ) => {
    //alert(id);
    e.preventDefault();
    let msg_title, msg = "";
    if(tes==="delete"){
      msg_title = "Delete Record";
      msg = "Are you sure to delete this job? You will not be able to undo this action.";
    }else{
      msg_title = "Mark Job As Completed";
      msg = "Are you sure to mark this job as completed? You will not be able to undo this action.";
    }
    this.setState({
      open : true,
      msg_title: msg_title,
      msg: msg,
      deleteJobId: id
    }); 
    //alert(msg)
  }

fetchNewRecord(){
  //console.log("in====")
  this.setState({ loading: true });
const docRef = this.props.firebase.traingLogDB().where('caseId', '==', this.props.jobID).where('denturist', '==', this.props.denturist);
docRef.orderBy('TimeRemaining','desc').orderBy('add_date','desc').get({source:'server'}).then((querySnapshot) => {
    
    let docs = [];
    let ttime = 0;
    let total_remaining = 0;
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
        docs.push(doc);
        ttime= parseInt(ttime)+parseInt(doc.data().TimeUsed);
    });
    total_remaining = (parseInt(this.props.HoursPurchased)*60)-parseInt(ttime);
    /*this.setState({
      docs: docs,
      loading: false,
    });*/
      this.setState({ 
        docs: docs,
        loading: false, 
        ttime : ttime,
        total_remaining: total_remaining
        }, function () {
           this.fetchRecord()
      });
  });
}

delete(id,e){
    e.preventDefault();
    this.setState({ isLoading: true })
    const that = this;
    //console.log(id);
    this.props.firebase.traingLogDB().doc(id).delete().then(() => {
      this.setState({ isLoading: false })
      this.handleClose(e);
      toast('Successfully deleted', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'success', onClose: () => { that.componentDidMount(); }
      });
    }).catch((error) => {
      console.error("Error deleting record: ", error);
      toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'error',
        });
    });
  }

componentDidMount() {

  this.fetchRecord()
}

  componentWillUnmount() {
    if (this.removeListener) {
      this.removeListener();
    }
  }

  /*componentWillReceiveProps(props) {
  const { refresh } = this.props;
  //console.log(this.props.refresh+'===='+refresh);
  if (props.refresh) {
    this.fetchRecord()
  }
}*/
componentWillReceiveProps(props) {
  //const { refresh } = this.props;
  //console.log(props);
  //if (props.refresh) {
    this.fetchNewRecord()
  //}
}

handleClose = (e) => {
    e.preventDefault();
    this.setState({
      open : false,
      deleteJobId : '',
      isLoading: false
    }); 
  }


render() {
  const {classes} = this.props;
    return (
        <div className={classes.root}>
        <Dialog
            maxWidth='xs'
            disableBackdropClick={true}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
        <DialogTitle id="alert-dialog-title">{this.state.msg_title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.state.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.handleClose} color="default">
            Cancel
          </Button>
          {
            this.state.msg_title==="Delete Record"
            ? <Button variant="contained" onClick={this.delete.bind(this,this.state.deleteJobId)} color="secondary">
                {this.state.isLoading ? (
                <div>
                  <ReactLoading
                    type={'spin'}
                    color={'#fff'}
                    height={'20%'}
                    width={'20%'}
                    className="tstmenu"
                  />
                  Deleting
                </div>
              ) : 'Delete'}
                  
                </Button>
            : ''
            }
        </DialogActions>
      </Dialog>

      <Paper className={classes.paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="center">Details</TableCell>
              <TableCell align="center">Time Used</TableCell>
              <TableCell align="center">Time Remaining</TableCell>
              { 
                (this.state.userRole==="Designer") &&
                <TableCell align="center"></TableCell>
              }
              
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (this.state.docs.length>0)
              ? this.state.docs.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {moment.unix(row.data().TrainingDate.seconds).format('MMM DD, YYYY')}
                </TableCell>
                <TableCell align="center">
                  {row.data().TrainingDetails}
                </TableCell>
                <TableCell align="center">{row.data().TimeUsed+' min.'}</TableCell>
                <TableCell align="center">{row.data().TimeRemaining+' min.'}</TableCell>
                
                { 
                  (this.state.userRole==="Designer")
                  ? <TableCell align="center">
                      <Link to={'/'} data-target="delete" data-title="Delete Record" onClick={this.handleClickOpen.bind(this, row.id, 'delete')}><Tooltip title="Delete" placement="top"><DeleteOutline/ ></Tooltip></Link>
                    </TableCell>
                  : ''
                }
              </TableRow>
            ))
              : <TableRow>
                <TableCell colSpan="4" component="td" scope="row" align="center">
                  No record found
                </TableCell>
                </TableRow>
            }
          </TableBody>
          <TableFooter>
              <TableRow>
              <TableCell colSpan={4} align="right">Time Purchased - {this.props.HoursPurchased*60} min.</TableCell>
              { 
                (this.state.userRole==="Designer") &&
                <TableCell align="center"></TableCell>
              }
              
            </TableRow>

              <TableRow>
              <TableCell colSpan={4} align="right">Total Time used - {this.state.ttime} min.</TableCell>
              { 
                (this.state.userRole==="Designer") &&
                <TableCell align="center"></TableCell>
              }
              
            </TableRow>
            
              <TableRow>
              <TableCell colSpan={4} align="right">Total Time Remaining - {this.state.total_remaining} min.</TableCell>
              { 
                (this.state.userRole==="Designer") &&
                <TableCell align="center"></TableCell>
              }
              
            </TableRow>
            
          </TableFooter>
              </Table>
              </Paper>
    </div>
    );
}
}
TrainingLog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withStyles(useStyles), withFirebase)(TrainingLog);