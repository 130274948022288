import React, { Component } from 'react';
import { AppString } from './../../components/Const';
import { withFirebase } from '../Firebase';
//import firebase from 'firebase';
import { TextField, Paper, Container, Typography, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { toast } from 'react-toastify';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
//import * as ROUTES from '../../constants/routes';
import { Modal } from 'react-bootstrap';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
//import './main.scss' // webpack must be configured to do this
import './bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import EditAvailability from '../Account/EditAvailability';

class Availability extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      id: localStorage.getItem(AppString.ID),
      userRole: localStorage.getItem('userRole'),
      show: false,
      startDate: '',
      endDate: '',
      unavailability_reason: '',
      arr:[],
      events: [],
      options: [],
      eventId: '',
      selectedEvent: {}
    };

  }

  handleDateSelect = (event) => {
    //console.log(event)
    this.setState({ 
        show: 'msg', 
        endDate : moment(event.endStr, "YYYY-MM-DD").subtract(1, 'day').format('YYYY-MM-DD'), 
        startDate : event.startStr, 
        eventId : '', 
        unavailability_reason:''
    });
  }

  handleClose = (event) => {
    this.setState({ show: false, options: [] });
  }

  handleChange = (event) => {
    // current array of options
      const options = this.state.options;
      let index
      // check if the check box is checked or unchecked
      if (event.target.checked) {
        // add the numerical value of the checkbox to options array
        options.push(event.target.value)
      } else {
        // or remove the value from the unchecked checkbox from the array
        index = options.indexOf(+event.target.value)
        options.splice(index, 1)
      }
      console.log(options)
      // update the state with the new array of options
      this.setState({ options: options })
  }

  handleDateClick = (arg) => { // bind with an arrow function
    const options = this.state.options;
    const that = this;
    this.props.firebase.availabilityDB()
    .where("userId", "==", this.state.id)
    .where('date', '==', arg.dateStr)
    .get({source:'server'}).then((querySnapshot) => {
         querySnapshot.forEach((doc) => {
            if (doc.exists) {
                options.push(doc.data().timeSlot);
                that.setState({
                  options: options
                })
            }
         })
    });
    this.setState({ show: 'msg',eventId : '', unavailability_reason:'' });
  }

  handleEventClick = (info) => {
    //console.log(info.event.date)
    /*console.log(moment(info.event.end).format('YYYY-MM-DD'))
    console.log(info.event.start)
    console.log(info.event.end)
    console.log(info.event.title)*/
    this.setState({ 
        show: 'edit', 
        endDate : moment(info.event.end).format('YYYY-MM-DD'), 
        startDate : moment(info.event.start).format('YYYY-MM-DD'), 
        unavailability_reason: info.event.title,
        eventId: info.event.id,
     });
  }

  onSubmit = event => {
    event.preventDefault();
    const unavailability_reason = this.state.unavailability_reason;
    const userId = this.state.id;
    const title = this.state.unavailability_reason;
    //const start = this.state.startDate;
    //const end = this.state.endDate;
    const allDay = false;
    const markBooked = false;
    const options = this.state.options;
    //let timeArr;
    var j=0;
    for (const [index, value] of options.entries()) {
      j=1;
      let timeArr = value.split(" - ");
      let startTime = timeArr[0];
      let endTime = timeArr[1];
      const start = this.state.startDate+' '+startTime;
      const end = this.state.endDate+' '+endTime;
      const timeSlot = value;
      const date = this.state.startDate;
      //console.log(start);
      //return false;

      const addref = this.props.firebase.availabilityDB();
      addref.add({
        title,
        userId,
        start,
        end,
        allDay,
        timeSlot,
        unavailability_reason,
        markBooked,
        date
      }).then((docRef) => {
          //this.props.history.push(ROUTES.AVAILABILITY); 
          this.setState({  // add new event data
              events: this.state.events.concat({ // creates a new array
                title: title,
                start: start,
                end: end,
                allDay: allDay
              })
            })
          this.handleClose();
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });

    }

    if(j===1){
      toast('Successfully Added', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
        type: 'success'
      });
    }

    /*const updateRef = this.props.firebase.availabilityDB();
    updateRef.add({
      title,
      userId,
      start,
      end,
      allDay,
      unavailability_reason,
    }).then((docRef) => {
       toast('Successfully Updated', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'success', onClose: () => { 
            //this.props.history.push(ROUTES.AVAILABILITY); 
            this.setState({  // add new event data
                events: this.state.events.concat({ // creates a new array
                  title: title,
                  start: start,
                  end: end,
                  allDay: allDay
                })
              })

            this.handleClose();
          }
      });
      
    })
      .catch((error) => {
        console.error("Error adding document: ", error);
        toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'success',
        });
      });*/

   
  };

  componentDidMount() {
    const that = this;
    this.props.firebase.availabilityDB().where("userId", "==", this.state.id)
      .get({source:'server'})
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              //console.log(doc.data());
              that.setState({  // add new event data
                events: that.state.events.concat({ // creates a new array
                  id: doc.id,
                  title: doc.data().title,
                  //title: doc.data().start+' - '+doc.data().end,
                  start: doc.data().start,
                  end: doc.data().end,
                  date: doc.data().start+' - '+doc.data().end
                })
              })
              
          })
      })
      //console.log(this.state.events);
      //console.log('ev',ev);

      this.setState({
        //events: this.state.events.concat(ev)
      })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChecked = event => {
    this.setState({ [event.target.name]: event.target.checked });
  }

  drawAvailability(){
    /*var quarterHours = ["00","00"];
    var times = [];
    var time2 = [];
    for(var i = 0; i < 24; i++){
        if(i<12){
          var a = 'AM';
        }else{
          var a = 'PM';
        }
        if(i%2){
          for(var j = 0; j < 1; j++){
            time2.push(i + ":00 "+a);
          }
      }else{
        for(var j = 0; j < 1; j++){
            times.push(i + ":00 "+a);
          }
      }
    }

    return times.map((val, idx)=> {
      //console.log(val)
      return (
        <li key={idx}>
          <FormControlLabel
              control={
                <Checkbox
                  checked={ this.state.options.indexOf(val+' - '+time2[idx]) < 0 ? false : true }
                  onChange={this.handleChange}
                  value={val+' - '+time2[idx]}
                  color="primary"
                />
              }
              label={val+' - '+time2[idx]}
          />
          </li>
      )
    })
*/

  }

  render() {
    const { options } = this.state;
    //console.log(this.state.events)
    //const isInvalid = userName === '' || error!==null;

    return (
      <div className="container">
      <div className="common-box">
        <Container component="main" maxWidth="lg">
          <Grid container spacing={2}>
          <Grid style={{ background:'#fff'}} className="job-dec box-shadow" item md={12} xs={12}>
          <Typography variant="body1" component="p">
            * Click a date to mark unavailable
          </Typography>
          <Typography variant="body1" component="p">
            * Click and drag to select multiple dates
          </Typography>

        <EditAvailability 
            show={this.state.show} 
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            eventId={this.state.eventId}
        />

       <form onSubmit={this.onSubmit}>
          <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.show==="msg"} onHide={this.handleClose}
                 backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Availability</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-12">
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="startDate"
                          label="Start Date"
                          type="text"
                          id="startDate"
                          value={this.state.startDate ? this.state.startDate : ''}
                          onChange={this.onChange}
                        />
                      
                        <TextField
                          
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="endDate"
                          label="End Date"
                          type="text"
                          id="endDate"
                          value={this.state.endDate ? this.state.endDate : ''}
                          onChange={this.onChange}
                        />

                        <div className="timeSlot">
                          <label>Select Available Time Slots</label>
                          <ul>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('12:00 - 01:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="12:00 - 01:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 12:00 AM - 01:00 AM"
                              />
                                
                            </li>

                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('01:00 - 02:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="01:00 - 02:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 01:00 AM - 02:00 AM"
                              />
                                
                            </li>

                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('02:00 - 03:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="02:00 - 03:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 02:00 AM - 03:00 AM"
                              />
                                
                            </li>

                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('03:00 - 04:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="03:00 - 04:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 03:00 AM - 04:00 AM"
                              />
                            </li>

                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('04:00 - 05:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="04:00 - 05:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 04:00 AM - 05:00 AM"
                              />
                            </li>

                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('05:00 - 06:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="05:00 - 06:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 05:00 AM - 06:00 AM"
                              />
                            </li>

                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('06:00 - 07:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="06:00 - 07:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 06:00 AM - 07:00 AM"
                              />
                            </li>

                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('07:00 - 08:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="07:00 - 08:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 07:00 AM - 08:00 AM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('08:00 - 09:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="08:00 - 09:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 08:00 AM - 09:00 AM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('09:00 - 10:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="09:00 - 10:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 09:00 AM - 10:00 AM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('10:00 - 11:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="10:00 - 11:00"
                                      color="primary"
                                    />
                                  }
                                  label=" 10:00 AM - 11:00 AM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('11:00 - 12:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="11:00 - 12:00"
                                      color="primary"
                                    />
                                  }
                                  label="11:00 AM - 12:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('12:00 - 13:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="12:00 - 13:00"
                                      color="primary"
                                    />
                                  }
                                  label="12:00 PM - 01:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('13:00 - 14:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="13:00 - 14:00"
                                      color="primary"
                                    />
                                  }
                                  label="01:00 PM - 02:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('14:00 - 15:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="14:00 - 15:00"
                                      color="primary"
                                    />
                                  }
                                  label="02:00 PM - 03:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('15:00 - 16:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="15:00 - 16:00"
                                      color="primary"
                                    />
                                  }
                                  label="03:00 PM - 04:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('16:00 - 17:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="16:00 - 17:00"
                                      color="primary"
                                    />
                                  }
                                  label="04:00 PM - 05:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('17:00 - 18:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="17:00 - 18:00"
                                      color="primary"
                                    />
                                  }
                                  label="05:00 PM - 06:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('18:00 - 19:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="18:00 - 19:00"
                                      color="primary"
                                    />
                                  }
                                  label="06:00 PM - 07:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('19:00 - 20:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="19:00 - 20:00"
                                      color="primary"
                                    />
                                  }
                                  label="07:00 PM - 08:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('20:00 - 21:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="20:00 - 21:00"
                                      color="primary"
                                    />
                                  }
                                  label="08:00 PM - 09:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('21:00 - 22:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="21:00 - 22:00"
                                      color="primary"
                                    />
                                  }
                                  label="09:00 PM - 10:00 PM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('22:00 - 23:00') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="22:00 - 23:00"
                                      color="primary"
                                    />
                                  }
                                  label="10:00 PM - 11:00 AM"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ options.indexOf('23:00 - 23:59') < 0 ? false : true }
                                      onChange={this.handleChange}
                                      value="23:00 - 23:59"
                                      color="primary"
                                    />
                                  }
                                  label="11:00 PM - 12:00 AM"
                              />
                            </li>
                          </ul>
                        </div>

                        <TextField
                          multiline
                          rowsMax="4"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="unavailability_reason"
                          label="Details"
                          type="text"
                          id="unavailability_reason"
                          className="unavailability_reason"
                          value={this.state.unavailability_reason ? this.state.unavailability_reason : ''}
                          onChange={this.onChange}
                          style={{display:"none"}}
                        />

                  </div>
                </div>

                </Modal.Body>
                <Modal.Footer>
                  <button className="btn" onClick={this.handleClose}>
                    Close
                  </button>&nbsp;
                  <button className="btn" onClick={this.onSubmit}>Save Changes</button>
                </Modal.Footer>
              </Modal>
          <Paper>
          <FullCalendar 
              defaultView="dayGridMonth" 
              plugins={[ dayGridPlugin, interactionPlugin ]}
              dateClick={this.handleDateClick}
              select={this.handleDateSelect}
              events={this.state.events}
              selectable = {true}
              eventClick = {this.handleEventClick}
              showNonCurrentDates = {false}
              eventLimit = '3'
          />
          </Paper>
          

      </form>
      </Grid>
      </Grid>
      </Container>
      </div>
      </div>
    );
  }
}

export default withFirebase(Availability);