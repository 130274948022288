const images = {
  ic_logout: require('./ic_logout.png'),
  ic_default_avatar: require('./ic_default_avatar.png'),
  ic_avatar: require('./ic_avatar.png'),
  ic_input_file: require('./ic_input_file.png'),
  ic_photo: require('./ic_photo.png'),
  ic_sticker: require('./ic_sticker.png'),
  ic_send: require('./ic_send.png'),
  ic_wave_hand: require('./ic_wave_hand.png'),

  mimi1: require('./mimi1.gif'),
  mimi2: require('./mimi2.gif'),
  mimi3: require('./mimi3.gif'),
  mimi4: require('./mimi4.gif'),
  mimi5: require('./mimi5.gif'),
  mimi6: require('./mimi6.gif'),
  mimi7: require('./mimi7.gif'),
  mimi8: require('./mimi8.gif'),
  mimi9: require('./mimi9.gif')
}

export default images
