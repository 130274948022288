import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import './WelcomeBoard.css'
import ReactLoading from 'react-loading';

export default class WelcomeBoard extends Component {
   constructor(props) {
    super(props);
    this.state = {
      user: this.props.currentUser
    };
    
  }


  render() {
    if(this.props.currentUserNickname){
    return (
      <div className="viewWelcomeBoard">
        <span className="textTitleWelcome">{`Welcome, ${
          this.props.currentUserNickname
        }`}</span>
        <img
          className="avatarWelcome"
          src={this.props.currentUserAvatar}
          alt="icon avatar"
        />
        <span className="textDesciptionWelcome">
          Let's start talking. Great things might happen.
        </span>
      </div>
    )
  }else{
   return (
    <div className="viewLoading">
            <ReactLoading
              type={'spin'}
              color={'#203152'}
              height={'7%'}
              width={'7%'}
              className="rloader"
            />
            Loading chatboard...
          </div>
    )
  }
  }
}
