import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import firebase from 'firebase';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { AppString } from './../../components/Const';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import './signin.css';

import { Paper, Container, Button, TextField, Typography } from '@material-ui/core';

const SignInPage = () => (
  <div>
  
    <Container component="main" maxWidth="xs">
	<div className="sign-box">
      <Paper>
        <Typography component="h1" variant="h5" color="primary">
          Sign in
        </Typography>
        <SignInForm />
        <PasswordForgetLink />
        <SignUpLink />
      </Paper>
	  </div>
    </Container>
  
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, isLoading: false, };
    const user_id = localStorage.getItem(AppString.ID);
    if(user_id){
    	this.props.history.push(ROUTES.HOME);

    }
  }

  onSubmit = event => {
  	
    const { email, password } = this.state;
    this.setState({ isLoading : true });
    const that = this;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
      	firebase.auth().onAuthStateChanged((thisUser) => {
      		//console.log(thisUser);
      		if(thisUser){
	      		this.props.firebase.usersDB().where("userId", "==", thisUser.uid)
	              .get({source:'server'})
	              .then(function(querySnapshot) {
                    that.setState({ isLoading : false });
                    if (querySnapshot.empty) {
                      toast('No matching account found. Please check your details.', { draggable:false,position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                          type: 'error',
                      });
                      return;
                    } 
	              	 
	                  querySnapshot.forEach(function(doc) {
                      let user = doc.data();
                      //console.log(doc)
                      if(user.status===0){
                        toast('Your account is not active. Please contact administartor for details', 
                          { 
                            position: toast.POSITION.TOP_CENTER, 
                            autoClose: 2000,
                            type: 'error'
                          });
                      }else{
                        localStorage.setItem(AppString.ID, user.userId)
                        localStorage.setItem(AppString.NICKNAME, user.username)
                        localStorage.setItem(AppString.USER_ROLE, user.userRole)
                        localStorage.setItem(AppString.PHOTO_URL, user.photoUrl)
                        localStorage.setItem(AppString.ABOUT_ME, user.aboutMe)
                        toast('Logging you in...', 
                            { 
                              position: toast.POSITION.TOP_CENTER, 
                              autoClose: 2000,
                              type: 'success', 
                              onClose: () => {
                                that.props.history.push(ROUTES.HOME); 
                                that.setState({ ...INITIAL_STATE }); 
                                window.location.reload(); 
                              }
                        });
                      }
	                      
	                  });
	                
	              })
	              .catch(function(error) {
	                  console.log("Error getting documents: ", error);
	              });
            }
      	});
        
        
        
      })
      .catch(error => {
        this.setState({ error });
         toast(error.message, { draggable:false,position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
		      type: 'error',
		  });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password } = this.state;

    const isInvalid = password === '' || email === '';

    return (
        <form noValidate onSubmit={this.onSubmit}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={this.onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={this.onChange}
          />
          {/*<Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isInvalid}
                    >
                      Sign In
                    </Button>*/}


          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isInvalid}
          >
                {this.state.isLoading ? (
                <div>
                  <ReactLoading
                    type={'spin'}
                    color={'#fff'}
                    height={'10%'}
                    width={'10%'}
                    className="tstmenu tst_loaded"
                  />
                   Processing
                </div>
              ) : 'Sign In'}
                  
                </Button>
          {/*error && <p>{error.message}</p>*/}
        </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };