import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumbs, TextField, Paper, Typography, InputLabel, Select, FormControl, Container, Grid, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withFirebase } from './../Firebase';
import * as ROUTES from './../../constants/routes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './case.css';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';



const useStyles = (theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: 5,
    fullWidth: true,
    display: 'flex',
    wrap: 'nowrap'
  },
}));

class CaseEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      key: '',
      Title: '',
      Description: '',
      Date: moment(),
      DisplayDate: moment(),
      users: [],
      mill: '',
      denturist: '',
      designer: '',
      millsList: [],
      designersList: [],
      status: '',
      value: '',
      designerName:'',
      millName: '',
      HoursPurchased: '',
      services: [],
            selectedService: ''
    };

    const userRole = localStorage.getItem('userRole');
    if(userRole!=="Denturist"){
        this.props.history.push(ROUTES.HOME);
        toast('Please logged in as Denturist to edit this job', 
          { position: toast.POSITION.TOP_CENTER, autoClose: 3000, type: 'error' });
    }

  }
  componentDidMount() {
    document.title = 'Edit Job - Digital Denture Experts'
    firebase.auth().onAuthStateChanged((thisUser) => {
      if (thisUser) {
        const ref = this.props.firebase.caseDB().doc(this.props.match.params.id);
        ref.get().then((doc) => {
          if (doc.exists) {
            const job = doc.data();
            var jt = job.jobType;
            var n = ''; 
            if(jt.includes('Designing Jobs')){
              n='Designing';
            }
            if(jt.includes('Milling Jobs')){
              n='Milling';
            }
            if(jt.includes('Training')){
              n='Training';
            }
            this.setState({
              key: this.props.match.params.id,
              Title: job.Title,
              Description: job.Description,
              Date: firebase.firestore.Timestamp.fromMillis(job.Date.seconds * 1000),
              DisplayDate: moment(job.Date.seconds * 1000),
              users: job.users,
              mill: job.mill,
              millName: job.millName,
              status: job.status,
              denturist: job.denturist,
              denturistName: job.denturistName,
              designer: job.designer,
              designerName: job.designerName,
              role:thisUser.userRole,
              userId:thisUser.uid,
              HoursPurchased: job.HoursPurchased,
              value:n,
              selectedService: job.selectedService
            });

            if(job.selectedService && job.selectedService!==""){
              if(job.mill && job.mill!==""){
                this.getServices(job.mill)  
              }
              if(job.designer && job.designer!==""){
                this.getServices(job.designer)  
              }
            }

            this.props.firebase.usersDB().where("userRole", "==", "Mill").where("status", "==", 1).where("hideAccount", "==", 0).get({ source: 'server' }).then((querySnapshot) => {
              let millsList = [];
              querySnapshot.forEach((mil) => {
                millsList.push({ ...mil.data(), id: mil.id });
              });
              this.props.firebase.usersDB().where("approved_roles", "array-contains", "Mill").where("status", "==", 1).where("hideAccount", "==", 0).get({source:'server'}).then((querySnapshot) => {
                   querySnapshot.forEach((doc) => {
                         millsList.push({...doc.data(), id: doc.id});
                   });
                  this.setState({
                    millsList,
                  });
                });
            });
            this.props.firebase.usersDB().where("userRole", "==", "Designer").where("status", "==", 1).where("hideAccount", "==", 0).get({ source: 'server' }).then((querySnapshot) => {
              let designersList = [];
              querySnapshot.forEach((des) => {
                designersList.push({ ...des.data(), id: des.id });
              });
              this.props.firebase.usersDB().where("approved_roles", "array-contains", "Designer").where("status", "==", 1).where("hideAccount", "==", 0).get({source:'server'}).then((querySnapshot) => {
                   querySnapshot.forEach((doc) => {
                         designersList.push({...doc.data(), id: doc.id});
                   });
                  this.setState({
                    designersList,
                  });
                });
            });
          } else {
            console.log("No such document!");
          }
        });
      }
    });
  }

  getServices(userID){
      const that = this;
      const services = [];
      this.props.firebase.servicesDB().where("userId", "==", userID)
      .get({source:'server'})
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              //console.log("slots",doc.data());
              services.push(doc);
          })
          that.setState({
              services: services,
          })
      })
  }

  onDateChange = event => {
    this.setState({
      Date: firebase.firestore.Timestamp.fromMillis(event.valueOf()),
      DisplayDate: event.valueOf()
    });
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onMillChange = event => {
    var vals = event.target.value;
    var res = vals.split("|");
    console.log(res[0])
    if(this.state.mill.length > 0){
      //remove previos mill from users array
      let users = this.state.users;
      users.filter(el => el !== this.state.mill);
      //add selected mill to users
      users.push(res[0]);
      this.setState(users);
    }
    this.getServices(res[0])
    this.setState({mill:res[0], millName: res[1],users: [], designer:'',designerName:''});
  };
  onDesignerChange = event => {
    var vals = event.target.value;
    var res = vals.split("|");
    if(this.state.designer.length > 0){
      //remove previos designer from users array
      let users = this.state.users;
      users.filter(el => el !== this.state.designer);
      //add selected designer to users
      users.push(res[0]);
      this.setState(users);  
    }

    if(this.state.value==="Training"){
      //this.getAvailability(res[0])
    }else{
      this.getServices(res[0])
    }

    this.setState({designer:res[0], designerName:res[1], users: [], mill:'', millName: '' });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if(this.state.mill.length > 0) {
        let users = this.state.users;
        users.push(this.state.mill);
        this.setState(users);
    }
    if(this.state.designer.length > 0) {
        let users = this.state.users;
        users.push(this.state.designer);
        this.setState(users);
    }

    if(this.state.denturist.length > 0) {
        let users = this.state.users;
        users.push(this.state.denturist);
        this.setState(users);
    }

    //const uniqueNames = Array.from(new Set(this.state.users));
    //this.setState({ users : uniqueNames });
    //console.log(uniqueNames);
    var u = [this.state.designer, this.state.mill, this.state.denturist]; 
    var filtered = u.filter(function (el) {
      return el !== "";
    });


    const { Title, Description, Date, mill, designer, denturist, designerName, millName, selectedService } = this.state;
    const users = filtered;
    const lastUpdated = firebase.firestore.Timestamp.fromMillis(moment());

    var st = this.state.status;
    if(st==="Posted" && (mill!=="" || designer!=="")){
      st = 'Assigned';
    }
    const status = st;
    
    const jobType = [];
    if(designer!==""){
      jobType.push('Designing Jobs');
    }
    if(mill!==""){
      jobType.push('Milling Jobs');
    }
    
    console.log(this.state)

    const denturistName = localStorage.getItem('nickname');

    const updateRef = this.props.firebase.caseDB().doc(this.state.key);
    updateRef.set({
      Title,
      Description,
      Date,
      mill,
      designer,
      users,
      denturist,
      denturistName,
      lastUpdated,
      status,
      designerName,
      millName,
      jobType,
      selectedService
    }).then((docRef) => {
      this.setState({
        key: '',
        Title: '',
        Description: '',
        Date: moment(),
        DisplayDate: moment(),
        mill:'',
        designer:'',
        denturist:'',
        denturistName:'',
        users:[],
        jobType:[],
        designerName:'',
        millName:'',
        selectedService:''
      });

      if(this.state.status==="Posted"){
        users.forEach((mil) => {
          if(mil!==denturist){
            const tu = [];
            tu.push(mil);
            const addDate = lastUpdated;
            const caseId = this.props.match.params.id;
            const fromUser = denturist;
            const toUsers = tu;
            const isRead = false;
            const nickname = localStorage.getItem('nickname');
            const subject = "New case assigned";
            const message = "New case assigned by "+nickname;
            const jobStatus = st;
            this.props.firebase.notificationsDB().add({ addDate,caseId,fromUser,toUsers,isRead,subject,message,jobStatus}).then(() => {
                  //console.log('tets')
            });
          }
        });
      }

       toast('Successfully Updated', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'success', onClose: () => { this.props.history.push(ROUTES.CASE_DISPLAY + this.props.match.params.id); }
      });
    }).catch((error) => {
        console.error("Error adding document: ", error);
        toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'success',
        });
      });
  }

  handleServiceChange = event => {
    //console.log(event.target.value);
    this.setState({selectedService:event.target.value});
  }

  handleChange = (event) => {
    this.setState({value:event.target.value, users:[], mill:'', millName: '', designer:'',designerName:''});
  }

  render() {
    //console.log(this.state.Date);
    const { classes } = this.props;
    const { Title, value, services } = this.state;
    const isInvalid = Title === '' || value === '';

    return (
      <div>
        <Breadcrumbs className="nav-breadcrumbs" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" to={ROUTES.HOME}>
            Jobs
          </Link>
          <Link color="inherit" to={ROUTES.CASE_DISPLAY + this.props.match.params.id}>
            {this.state.Title}
          </Link>
          <Typography color="textPrimary">Edit Job</Typography>
        </Breadcrumbs>
    
		 <div className="container">
		  <div className="common-box">
		<Container component="main" maxWidth="xs" className="caseEdit">
        <Grid container  spacing={2}>
        <Grid className="case-edit" item md={12} xs={12}>
        <Paper >
          <form onSubmit={this.onSubmit} className={classes.container} noValidate>
            <TextField
              id="Title"
              name="Title"
              label="Title"
              style={{ margin: 8 }}
              variant="outlined"
              placeholder="Title"
              helperText="Title is the case Id that you want to assign"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.Title}
              onChange={this.onChange}
            />

            <TextField
              id="Description"
              name="Description"
              label="Description"
              variant="outlined"
              helperText="You can add description for details"
              multiline
              fullWidth
              style={{ margin: 8, width: '100%' }}
              onChange={this.onChange}
              className={classes.textField}
              margin="normal"
              value={this.state.Description}
            />

            <FormControl component="div" className={classes.formControl}>
              <FormLabel component="label">Job Type *</FormLabel>
              <RadioGroup
                aria-label="Job Type"
                name="gender1"
                className='radio_job_type'
                value={this.state.value}
                onChange={this.handleChange}
              >
                <FormControlLabel value="Milling" control={<Radio color="default" />} label="Milling" />
                <FormControlLabel value="Designing" control={<Radio color="default" />} label="Designing" />
                <FormControlLabel value="Training" control={<Radio color="default" />} label="Training" />
              </RadioGroup>
            </FormControl>


            {
              this.state.value==="Milling"
              ? <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="mill-id">Select a Mill</InputLabel>
                  <Select
                    native={true}
                    value={this.state.mill+'|'+this.state.millName}
                    onChange={this.onMillChange}
                  >

                    {this.state.millsList.map(doc => (
                      <option disabled={doc.isUnavailable} key={doc.id} value={doc.userId+'|'+doc.username}>{doc.username}
                      { 
                        (doc.isUnavailable && doc.unavailability_reason && doc.unavailability_reason!=="" && doc.unavailability_reason!=="undefined")
                        ? ' ('+doc.unavailability_reason+')'
                        : ''
                      }
                      </option>
                    ))};
                  </Select>
                </FormControl>
              : ''
            }

            {
              (this.state.value==="Designing" || this.state.value==="Training")
              ? <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="designer-id">Select a Designer</InputLabel>
                  <Select
                    native={true}
                    value={this.state.designer+'|'+this.state.designerName}
                    onChange={this.onDesignerChange}
                  >

                    {this.state.designersList.map(doc => (
                      <option disabled={doc.isUnavailable} key={doc.id} value={doc.userId+'|'+doc.username}>{doc.username}
                      { 
                        (doc.isUnavailable && doc.unavailability_reason && doc.unavailability_reason!=="" && doc.unavailability_reason!=="undefined")
                        ? ' ('+doc.unavailability_reason+')'
                        : ''
                      }
                      </option>
                    ))};
                  </Select>
                </FormControl>
              : ''
            }

            <p>&nbsp;</p>
            {
            (this.state.value==="Training")
            ? <TextField
                id="HoursPurchased"
                name="HoursPurchased"
                label="# of Hrs Purchased *"
                style={{ margin: 8 }}
                variant="outlined"
                placeholder=""
                helperText="Enter only numeric value"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                value={this.state.HoursPurchased}
                onChange={this.onHrsChange}
            /> 
            : ''

            }

            {
                (this.state.value!=="Training" && services.length>0) &&
                <FormControl className={classes.formControl}>
                  <p><label>Select Product</label></p>
                  <table>
                  <tbody>
                  { 
                    (this.state.value!=="Training" && services.length>0) &&
                    services.map(doc => (
                      <tr key={doc.id}>
                        <td>
                          <Radio
                            checked={this.state.selectedService === doc.id}
                            onChange={this.handleServiceChange.bind(this)}
                            value={doc.id}
                            color="default"
                            name="radio-button-demo"
                            size="small"
                          />
                        </td>
                        <td>{doc.data().Product}</td>
                        <td>${doc.data().Cost}</td>
                        <td>{doc.data().Notes}</td>
                      </tr>
                    ))
                  }
                    <tr key='others'>
                        <td>
                          <Radio
                            checked={this.state.selectedService === 'others'}
                            onChange={this.handleServiceChange.bind(this)}
                            value="others"
                            color="default"
                            name="radio-button-demo"
                            size="small"
                          />
                        </td>
                        <td colspan="3">Others</td>
                    </tr>
                  </tbody>
                  </table>
                </FormControl>
            }

            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker format='MMM DD, YYYY hh:mm A' name="DisplayDate" value={moment(this.state.DisplayDate)} onChange={this.onDateChange} />
              </MuiPickersUtilsProvider>
            </FormControl>
            { this.state.status!=='Completed'
             ? <button type="submit" className="btn btn-success round-btn" disabled={isInvalid}>Submit<KeyboardArrowRightOutlinedIcon fontSize="small" className="icon-size" /></button>
             : <button type="submit" className="btn btn-success round-btn" disabled='true'>Job Completed <KeyboardArrowRightOutlinedIcon fontSize="small" className="icon-size" /></button>
            }
            
          </form>
        </Paper>
		</Grid>
		</Grid>
		 </Container>
      </div>
	  </div>
	  </div>
    );
  }
}
CaseEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withFirebase(withStyles(useStyles)(CaseEdit));