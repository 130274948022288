import React, { useEffect } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import { Grid, Paper, Typography } from '@material-ui/core';
//import FirebaseFileUpload from './../FileUpload/FirebaseFileUpload';
//import ImageGallery from './../Gallery/ImageGallery';
//import Root from './../Home/Root/Root';
//import DocumentList from '../Gallery/DocumentList';
import { withAuthorization, AuthUserContext } from '../Session';
//import ChatBoard from './../messaging/ChatBoard/ChatBoard';
import Case from './../Case';
import './home.css';

/*const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
}));*/
const HomePage = () => {

useEffect(() => {
    // Update the document title using the browser API
    document.title = `Dashboard - Digital Denture Experts`;
  });

return(
    <AuthUserContext.Consumer>
        {authUser => 
        <div>
		 {/*<div className="top-strip">
		 <div className="container">
                         <h4>Hello {authUser.username}</h4>
                         </div>
				</div>*/}
                <Case/>
                </div>
        }
    </AuthUserContext.Consumer>
);
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);