export const AppString = {
  ID: 'id',
  PHOTO_URL: 'photoUrl',
  NICKNAME: 'nickname',
  ABOUT_ME: 'aboutMe',
  NODE_MESSAGES: 'messages',
  NODE_USERS: 'users',
  UPLOAD_CHANGED: 'state_changed',
  DOC_ADDED: 'added',
  PREFIX_IMAGE: 'image/',
  USER_ROLE: 'userRole'
}
