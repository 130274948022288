import React, { Component } from 'react';
import {withFirebase} from './../Firebase';
import { Link } from 'react-router-dom';
import { Table, TableRow, TableCell, TableHead, TableBody, TextField, Grid, Paper, Container, Tooltip } from '@material-ui/core';
import { compose } from "recompose";
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import { AppString } from './../../components/Const';
import * as ROUTES from '../../constants/routes';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import ReactLoading from 'react-loading';
import './service.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Services extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userRole: localStorage.getItem('userRole'),
      Product : '',
      Cost: '',
      Notes: '',
      open: false,
      isLoading: false,
      services: [],
      userId: localStorage.getItem(AppString.ID),
      UserName: localStorage.getItem('nickname'),
      serviceId:'',
      loading:true,
      page: 0,
      rowsPerPage: 10,
      user_id : localStorage.getItem(AppString.ID)
    };
    
    const user_id = localStorage.getItem(AppString.ID);
    if(!user_id){
      this.props.history.push(ROUTES.SIGN_IN);
    }
    if(localStorage.getItem('userRole')==="Denturist"){
      toast('Please logged in as designer/mill to access this page', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'error'
      });
      this.props.history.push(ROUTES.HOME);
    }

  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    document.title = 'Products/Services';
    this.getData();
  }

  getData(){
      const that = this;
      const user_id = localStorage.getItem(AppString.ID);
      const services = [];
      this.props.firebase.servicesDB().where("userId", "==", user_id)
      .get({source:'server'})
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              //console.log("slots",doc.data());
              services.push(doc);
          })
          that.setState({
              services: services,
              loading:false
          })
      })
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    /*stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });*/
    return stabilizedThis.map(el => el[0]);
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage})
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value})
  }

  getSelectedProduct(id){
    console.log(id)
    const docRef = this.props.firebase.servicesDB().doc(id);
    docRef.get({source:'server'}).then(doc => {
    if (doc.exists) {
       this.setState({
          Product: doc.data().Product,
          Cost: doc.data().Cost,
          Notes: doc.data().Notes,
          userId: doc.data().userId,
          UserName: doc.data().UserName,
          userRole: doc.data().userRole,
          serviceId: id,
        });
      } else {
        console.log("No such document!");
        /*toast("Please check your URL. Either the URL is invalid or the job doesn't exists", { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'success', onClose: () => { this.props.history.push("/home") }
        });*/
      }
    });
  }

  handleClickOpen = (id,tes, e ) => {
    e.preventDefault();

    let msg_title, msg = "";
    if(tes==="delete"){
      msg_title = "Delete Product";
      msg = "Are you sure to delete this product? You will not be able to undo this action.";
    }else if(tes==="add"){
      msg_title = "Add Product";
      msg = "";
      this.setState({
         Cost:'', Notes:'',Product: '', serviceId: ''
      }); 
    }else{
      msg_title = "Edit Product";
      msg = "";
      this.getSelectedProduct(id);
    }
    this.setState({
      open : true,
      msg_title: msg_title,
      msg: msg,
      deleteJobId: id
    }); 
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState({
      open : false,
      deleteJobId : '',
      Product:'',
      Notes:'',
      Cost: '',
      isLoading: false
    }); 
  }

  delete(id,e){
    e.preventDefault();
    this.setState({ isLoading: true })
    //console.log(id);
    this.props.firebase.servicesDB().doc(id).delete().then(() => {
      this.setState({ isLoading: false })
      this.handleClose(e);
      toast('Successfully deleted', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'success'
      });
    }).catch((error) => {
      console.error("Error deleting product: ", error);
      toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'error',
        });
    });
    this.getData();
  }

  onSubmit = (clicked, e ) => {
      e.preventDefault();
      console.log(clicked)
      const { serviceId, Product, Notes, Cost, userId, UserName } = this.state;
      const userRole = localStorage.getItem('userRole');
      if(serviceId!==""){//update record
         const updateRef = this.props.firebase.servicesDB().doc(serviceId);
          updateRef.set({
            Product,
            Notes,
            Cost,
            userId,
            UserName,
            userRole,
          }).then((docRef) => {
              if(clicked==="save_close"){
                  this.handleClose(e);
              }
             toast('Successfully Updated', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                type: 'success'
             });
          }).catch((error) => {
            console.error("Error adding document: ", error);
            toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                type: 'success',
            });
          });
      }else{ //add record
          this.props.firebase.servicesDB().add({
            Product,
            Notes,
            Cost,
            userId,
            UserName,
            userRole,
        }).then((docRef) => {
             //doc_id = docRef.id;
            toast('Successfully Added', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                type: 'success'
             });
            if(clicked==="save_close"){
                this.handleClose(e);
            }else{
              this.setState({
                Product:'',
                Notes:'',
                Cost: '',
              })
            }
        }).catch((error) => {
              console.error("Error adding document: ", error);
        });
      }
      this.getData();
  }

  render() {
    const {Product, Cost, services,userRole} = this.state;
    const isInvalid = Product === '' || Cost==='';
    console.log(userRole)
    return (
      <div>
       
       <Dialog
            maxWidth='xs'
            disableBackdropClick={true}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >

          <DialogTitle id="alert-dialog-title">{this.state.msg_title}
            {
              (this.state.msg_title && this.state.msg_title!=="Delete Product") &&
                <Link to={'/'} className="right" onClick={this.handleClose}>X</Link>
            }
          </DialogTitle>

          {
              (this.state.msg_title && this.state.msg_title==="Delete Product") &&
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {this.state.msg}
                  </DialogContentText>
                </DialogContent>
          }
          {
              (this.state.msg_title && this.state.msg_title==="Delete Product") &&
              <DialogActions>
                <Button variant="contained" onClick={this.delete.bind(this,this.state.deleteJobId)} color="secondary">
                {this.state.isLoading ? (
                <div>
                  <ReactLoading
                    type={'spin'}
                    color={'#fff'}
                    height={'20%'}
                    width={'20%'}
                    className="tstmenu"
                  />
                  Deleting
                </div>
              ) : 'Delete'}
                  
                </Button>


                <Button variant="contained" onClick={this.handleClose} color="default">
                  Cancel
                </Button>
              </DialogActions>
          }

          {
              (this.state.msg_title && this.state.msg_title!=="Delete Product") &&
              <Grid className="box-shadow product-modal" item md={12} xs={12}>
           <Paper>
           <div style={{ paddingRight:'18px'}}>
              <form noValidate>
                            <TextField
                                id="Product"
                                name="Product"
                                label="Product *"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.Product}
                                onChange={this.onChange}
                            />
                            <TextField
                                id="Cost"
                                name="Cost"
                                label="Cost *"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.Cost}
                                onChange={this.onChange}
                            />

                            <TextField
                                multiline
                                id="Notes"
                                name="Notes"
                                label="Notes"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                    readOnly: true,
                                }}
                                value={this.state.Notes}
                                onChange={this.onChange}
                            />

                            {
                              (this.state.msg_title==="Add Product") &&
                                <Button 
                                  className="btn btn-orange"
                                  type="submit"
                                  disabled={isInvalid}
                                  onClick={this.onSubmit.bind(this,"save_and_add")} 
                                  style={{ marginRight:'15px', textAlign:'left'}}
                                >Save & Add More </Button>
                            }

                            <button 
                              className="btn btn-danger"
                              type="submit"
                              disabled={isInvalid}
                              onClick={this.onSubmit.bind(this,"save_close")} 
                              style={{ marginRight:'15px'}}
                            >Save & Close </button>
                            
                    </form>
                    </div>
            </Paper>
          </Grid>
          }
      </Dialog>
       
    
      <div className="container">
      <div className="common-box newJobs" style={{ minHeight:'auto'}}>
      <Link component="button" to={'/'} data-target="add" onClick={this.handleClickOpen.bind(this, "", 'add')} className="btn btn-primary round-btn right add"><AddCircleOutlineSharpIcon fontSize="small" className="icon-size" /> Add New Product</Link>

      </div>
     <div className="common-box">
        <Container maxWidth="lg">
        <Grid container  spacing={2}>
          <Grid item md={12} xs={12}>
           <Paper className="tableContainer noPadding" spacing={2}>
            <Table className="table table-stripe new-Job">
              <TableHead>
                <TableRow>
                  {/*<TableCell>Id</TableCell>*/}
                  <TableCell align="right" >Product</TableCell>
                  <TableCell align="right">Cost</TableCell>
                  <TableCell align="right">Notes</TableCell>
                  <TableCell align="right" width="20%">Action</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                { 
                  this.state.loading 
                  ? <TableRow><TableCell colSpan="6" style={{ textAlign : 'center'}}><CircularProgress /></TableCell></TableRow>
                  : (services.length)
                    ? 
                    this.stableSort(services)
                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map(doc => (
                <TableRow key={doc.id}>
                  {/*<TableCell>{doc.id}</TableCell>*/}
                  <TableCell>{doc.data().Product}</TableCell>
                  <TableCell>${doc.data().Cost}</TableCell>
                  <TableCell>{doc.data().Notes}</TableCell>
                  <TableCell>
                    <Link to={'/'} data-target="edit" data-title="Edit Job" onClick={this.handleClickOpen.bind(this, doc.id, 'edit')}><Tooltip title="Edit Product" placement="top"><EditOutlined/ ></Tooltip></Link>
                    <Link to={'/'} data-target="delete" data-title="Delete Product" onClick={this.handleClickOpen.bind(this, doc.id, 'delete')}><Tooltip title="Delete" placement="top"><DeleteOutline/ ></Tooltip></Link>
                  </TableCell>
                </TableRow>
                ))
                : <TableRow><TableCell colSpan="5" style={{ textAlign : 'center'}}>No record found</TableCell></TableRow>



                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={services.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
           </Grid>

          </Grid>
            </Container>
            </div>
      </div>
      </div>
    );
  }
}

//export default withFirebase(Services);
export default compose(withFirebase)(Services);